import React from 'react';
import './css/ResultsUrls.css';
function ResultsKeywords(props) {

  return (
    <div className="ResultsUrlMain">
      <div className="ResultsUrl">{props.url}</div>
    </div>
  );
}

export default ResultsKeywords;