// import Global from './Global';
// import SailorContent from './store/SailorContext';

// function occurrences(string, subString, allowOverlapping) {

//     string += "";
//     subString += "";
//     if (subString.length <= 0) return (string.length + 1);

//     var n = 0,
//         pos = 0,
//         step = allowOverlapping ? 1 : subString.length;

//     while (true) {
//         pos = string.indexOf(subString, pos);
//         if (pos >= 0) {
//             ++n;
//             pos += step;
//         } else break;
//     }
//     return n;
// }

class Extensions {
    static strip(html) {
        let string = String(html);
        // Remove style tags and content
        return string.replace(/<style[^>]*>.*<\/style>/gm, '')
            // Remove script tags and content
            .replace(/<script[^>]*>.*<\/script>/gm, '')
            // Remove all opening, closing and orphan HTML tags
            .replace(/<[^>]+>/gm, '')
            // Remove leading spaces and repeated CR/LF
            .replace(/([\r\n]+ +)+/gm, '');
    }

    static counterKeywords(text, id, arrayContents) {
        const result = arrayContents.filter(obj => {
            return obj.id === id
        });
        let work_with_x = result[0];
        let masterArray = [];
        if (work_with_x != null) {
            let keywords = work_with_x.avg_keyword_counters;

            for (let i = 0; i < keywords.length; i++) {
                // const element = keywords[i].keyword;
                // let count = (text.match(/element/g) || []).length;
                let count = this.occurrences(text.toLowerCase(), keywords[i].keyword.toLowerCase(), true);
                // console.log(count);
                masterArray.push({ keyword: keywords[i].keyword, count: count, ideal: keywords[i].avg});       
            }
        }

        return(masterArray);
    }

    static occurrences(string, subString, allowOverlapping) {

        string += "";
        subString += "";
        if (subString.length <= 0) return (string.length + 1);
    
        var n = 0,
            pos = 0,
            step = allowOverlapping ? 1 : subString.length;
    
        while (true) {
            pos = string.indexOf(subString, pos);
            if (pos >= 0) {
                ++n;
                pos += step;
            } else break;
        }
        return n;
    }

    static wordCounter(text){
        let res = text.split(" ");
        return res.length;
    }

    static idExists(id, arrayContents){
        const result = arrayContents.filter(obj => {
            return obj.id === id
        });
        if (result[0]!= null) {
            return true;
        }
        return false;
    }

}

export default Extensions;