import ReactGA from "react-ga";

const eventTrack = (category, action, label, value) => {
  console.log("GA event:", category, ":", action, ":", label, ":", value);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
  });
};

export default eventTrack;
