import React, { useState } from "react";
import "./css/KwcProject.css";

function BulkDetectorProject(props) {
  const detector_project_click_handler = () => {
    props.selection(props.id);
  };

  let counter = props.god_layer;

  return (
    <div className="kwc-proj" onClick={detector_project_click_handler}>
      <div className="kwc-proj-seed">Seed:</div>
      <div className="kwc-proj-seed-number">{props.seed}</div>
      <div className="kwc-proj-kwc-extracted">Urls Checked:</div>
      <div className="kwc-proj-kwc-extracted-counter">{counter}</div>
      <div className="kwc-proj-kwc-done">Done:</div>
      <div className="kwc-proj-kwc-done">{props.done}</div>
    </div>
  );
}

export default BulkDetectorProject;
