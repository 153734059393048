import "./css/Generator.css";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Extensions from "../Extensions";
import ClickBankAds from "./ClickBankAds";
import Tooltip from "./Tooltip";
import CheckJWTer from "../hooks/useCheckJWT";

let ce = null;

function Generator(props) {
  // const [balance, setBalance] = useState("pulling balance...");
  const [words_left, setWords_left] = useState(0);
  const [gen_content, setGen_content] = useState(
    'Delete this text and replace it with your own text to generate, remember that this tool "continues" what you are already writing...'
  );
  const [initial_content, setInitial_content] = useState(
    'Delete this text and replace it with your own text to generate, remember that this tool "continues" what you are already writing...'
  );
  const [maxTokens, setMaxTokens] = useState(Math.round(15 * 1.3));
  const [maxWords, setMaxWords] = useState(15);
  const [temperature, setTemperature] = useState(0.95);
  const [topP, setTopP] = useState(0.9);
  const [generating, setGenerating] = useState(false);
  const context = useContext(SailorContent);
  // const history = useHistory();
  const [data] = CheckJWTer();

  const childFunc = React.useRef(null);

  let tt =
    "Temperature, default 0.95: higher means the model will take more risks. TopP, default 0.9: higher means more random tokens. We recommend changing either temperature or top_p but not both";

  //this captures the paste event and only pastes the raw plain text
  useEffect(() => {
    ce = document.querySelector(".text-box");
    ce.addEventListener("paste", function (e) {
      e.preventDefault();
      var text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    });
  }, []);

  const bal_response = () =>
    fetch(context.api_url + "GenerateText/pull-balance", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("bal")) {
          let numb = rx.bal;
          numb = +numb.toFixed(2);
          // setBalance(numb);
          if (!isNaN(rx.bal) && numb > 0) {
            // setWords_left(rx.bal * 2000);
            // setBalance("infinite");
            setWords_left("∞");
          } else if (!isNaN(rx.bal) && numb <= 0) {
            // setBalance("verify your account");
            setWords_left("verify your account");
          }
        } else {
          setWords_left("error :(");
        }
      })
      .catch(() => {
        return { bal: "error :(" };
      });
  useEffect(() => {
    try {
      bal_response();
    } catch (ex) {
      return;
    }
  }, []);

  const generatorContentHandler = (event) => {
    // let raw = event.target.innerText;
    // let cleaned = Extensions.strip(raw);
    setGen_content(event.target.innerText);
    //try console.log(event.target);
    //to see all the properties and get the one that doesn't
    //clears the formatting
    // console.log(event.target.outerText);
    // console.log(event.target.innerText);
  };

  const tempHandler = (event) => {
    setTemperature(event.target.valueAsNumber);
  };

  const topPHandler = (event) => {
    setTopP(event.target.valueAsNumber);
  };

  const maxTokensHandler = (event) => {
    setMaxTokens(event.target.valueAsNumber * 1.3);
    setMaxWords(event.target.valueAsNumber);
  };

  const addNewContent = (new_content) => {
    let raw = new_content;
    let cleaned = Extensions.strip(raw);
    // let cleanedx = cleaned.split("\n").map((line, i) => <div key={i}>{line}</div>);
    let gen_c = gen_content + " " + cleaned + " ";
    setGen_content(gen_c);
    setInitial_content(gen_c);
  };

  const generateHandler = () => {
    if (generating) {
      return;
    }
    //the childFunc thing is to move to another clickbank ad
    childFunc.current();
    setGenerating(true);
    let generation = {
      maxTokens: maxTokens,
      temperature: temperature,
      topP: topP,
      generate_content: gen_content,
    };
    fetch(context.api_url + "GenerateText/generate", {
      method: "post",
      body: JSON.stringify(generation),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        //set here the new text generated as well as the new balance
        if (rx.hasOwnProperty("bal") && rx.hasOwnProperty("text")) {
          let numb = rx.bal;
          numb = +numb.toFixed(2);
          addNewContent(rx.text);
        } else {
          addNewContent("servers caught fire, thank you");
        }
        setGenerating(false);
      })
      .catch(() => {
        setGenerating(false);
        addNewContent("servers caught fire, thank you");
      });
  };

  return (
    <div>
      <MainNavigation current="AI Generator" />
      <div className="generator">
        <div className="text-box" contentEditable="true" suppressContentEditableWarning onInput={generatorContentHandler}>
          {initial_content}
        </div>
        <div className="gen-info">
          {/* <div>Words Left:</div>
          <div className="gen-balance">{words_left}</div> */}
          {/* <button onClick={() => history.push("/refill")}>Refill</button> */}
          <div className="gen-text">AI reads ~600 words back from the last character</div>
          <ClickBankAds childFunc={childFunc} />
          <div className="sliders-container-ai">
            {/* <div className="knowledge-divs">Temperature, default 0.95: higher means the model will take more risks.</div>
            <div className="knowledge-divs">TopP, default 0.9: higher means more random tokens.</div>
            <div className="knowledge-divs">We recommend changing either temperature or top_p but not both</div> */}
            <div className="sliders-flex">
              <div>
                <div>
                  Temp: <span>{temperature}</span>
                </div>
                <input type="range" min="0.1" max="1.0" step="0.01" className="slider-ai" onChange={tempHandler} value={temperature} />
              </div>
              <div>
                <div>
                  TopP: <span>{topP}</span>
                </div>
                <input type="range" min="0.1" max="1.0" step="0.01" className="slider-ai" onChange={topPHandler} value={topP} />
              </div>
              <div>
                <div>
                  # Words: <span>{maxWords}</span>
                </div>
                <input type="range" min="2" max="22" className="slider-ai" onChange={maxTokensHandler} value={maxWords} />
              </div>
            </div>
            <Tooltip content={tt}>
              <div>Hover/Tap for info</div>
            </Tooltip>
            <button onClick={generateHandler}>{generating ? "GENERATING..." : "🤖 GENERATE"}</button>
            <div className="gen-link">
              <a href="https://b.kazanseo.com/samples" target="_blank" rel="noreferrer">
                Instructions Here
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Generator;
