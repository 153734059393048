import React from "react";
import "./css/TextExtractionProjects.css";

function TextExtractionProjects(props) {
  const TextExtractionProjectsHandler = () => {
    props.ShowInResults(props.text);
  };

  return (
    <div
      className="extraction-projects"
      onClick={TextExtractionProjectsHandler}
    >
      <div className="extraction-project-namer">Urls Extracted:</div>
      <div className="extraction-project-urls-total">{props.urls_count}</div>
      <div className="extraction-project-date">{props.date}</div>
      <div className="extraction-project-total-text">Total Text Extracted</div>
      <div className="extraction-project-words">{props.words}</div>
    </div>
  );
}

export default TextExtractionProjects;
