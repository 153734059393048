import React, { useState, useEffect, useContext } from "react";
import "./css/Refill.css";
import CardFormPaypalSub from "./CardPaypalSub";
// import {Elements} from "@stripe/react-stripe-js";
// import {loadStripe} from "@stripe/stripe-js";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";

function Refill(props) {
  const [card_failed_or_not, setCard_failed_or_not] = useState(<h1> </h1>);
  const [amount_refill, set_amount_refill] = useState(25);
  const [balance, setBalance] = useState("pulling balance...");
  const [estimated_words, setEstimated_words] = useState(62500);
  const context = useContext(SailorContent);

  const bal_response = () =>
    fetch(context.api_url + "GenerateText/pull-balance", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("bal")) {
          setBalance(rx.bal);
        } else {
          setBalance("error pulling the balance, sorry :( try again");
        }
      })
      .catch(() => {
        return { bal: "error pulling balance, sorry :( try again" };
      });
  useEffect(() => {
    try {
      bal_response();
    } catch (ex) {
      return;
    }
  }, []);

  const refillClick = () => {
    // setCard_failed_or_not(<CardFormPaypalSub amount={amount_refill} />);
    setCard_failed_or_not(<CardFormPaypalSub />);
  };

  const onChangeRangeHandler = (event) => {
    set_amount_refill(event.target.valueAsNumber);
    setEstimated_words(event.target.valueAsNumber * 2500);
    setCard_failed_or_not(<div></div>);
  };

  return (
    <div>
      <MainNavigation current="Refill" />
      <div className="refill">
        <h2>Current Balance: ${balance}</h2>
        <h1 className="zx">Refill however much you want!</h1>
        <h3>No subscriptions, just one time payments</h3>
        <div className="slidecontainer">
          <input type="range" min="20" max="200" className="slider" step="5" onChange={onChangeRangeHandler} value={amount_refill} />
          <h2>
            Refill Amount: $<span>{amount_refill}</span>
          </h2>
          <div>Around {estimated_words} ~ words give or take</div>
        </div>
        <button onClick={refillClick}>Refill (opens paypal payment form)</button>
        {card_failed_or_not}
      </div>
    </div>
  );
}

export default Refill;
