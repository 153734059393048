import React, { useState, useEffect, useContext } from "react";
import ProjectForm from "./ProjectForm";
import "./css/NewProject.css";
import ProjectCreation from "./ProjectCreation";
import Extensions from "../Extensions";
// import Global from '../Global';
import SailorContent from "../store/SailorContext";
// import {Promise} from "bluebird";

let controller = null;
let signal = null;
let keyword_proj = "";

function getCharacter(keyword, context) {
  controller = new AbortController();
  signal = controller.signal;
  let jsonObject = { proj: keyword, user_id: context.user_id };
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + "SailorMonolithic/AddProject", {
        method: "post",
        signal: signal,
        body: JSON.stringify(jsonObject),
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "localhost",
          Authorization: `Bearer  ${context.jwt}`,
        },
        mode: "cors",
      });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        const data_err = await response.text();
        reject(data_err);
      }
    } catch (ex) {
      if (isAbortError(ex)) {
        console.log(ex.message, " abort message");
        reject("aborted");
      } else {
        console.log(ex, " some other error message that aborted the promise");
        reject("random other error threw the promise");
      }
    }
  });
  promise.cancel = () => controller.abort();
  return promise;
}

function isAbortError(error) {
  if (error && error.name === "AbortError") {
    return true;
  }
  return false;
}

function NewProject(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [query, setQuery] = useState(null);

  const context = useContext(SailorContent);

  const startProjectHandler = (enteredProject) => {
    //do something to upload a project
    setIsEditing(false);
    setIsCreating(true);

    createProject(enteredProject);
  };

  function submitProject(keyword) {
    setErrMessage("");
    const q = getCharacter(keyword, context);
    setQuery(q);
    q.then((character) => {
      console.log("success");
      // console.log(character);
      context.contents.unshift(character);
      props.newProj(context.contents);
      // console.log(context.contents);
      setIsEditing(false);
      setIsCreating(false);
      props.pullps();
    }).catch((whattodo) => {
      console.log(whattodo, "error from submitProject");
      setErrMessage(whattodo);
      setIsEditing(false);
      setIsCreating(false);
      // props.pullps();
    });
  }

  const createProject = (projName) => {
    submitProject(projName.replace(" ", "+"));
  };

  const startEditingHandler = () => {
    setIsEditing(true);
  };
  const stopEditingHandler = () => {
    setIsEditing(false);
    setIsCreating(false);
  };

  const onCancelProjectHandler = (project) => {
    controller.abort();
    query.cancel();
  };

  let stateButton = <button onClick={startEditingHandler}>Optimize New Keyword</button>;

  if (isEditing) {
    stateButton = <ProjectForm onStartProject={startProjectHandler} onCancel={stopEditingHandler} />;
  } else if (isCreating) {
    stateButton = <ProjectCreation onCancel={onCancelProjectHandler} />;
  }

  return <div className="new-project">
    <div className="new-project-err-msg">{errMessage}</div>
    {stateButton}
  </div>;
}

export default NewProject;
