import React, { useState, useEffect, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./css/Card.css";
import SailorContent from "../store/SailorContext";
import CardPaypalSubEmails from "./CardPaypalSubEmails";

function CardFormPaypalSubEmailsToggle() {
  return (
    <div className="emailer-sub-columns">
      <div className="sub-column">
        <div className="sub-col-label">
          <strike className="strike-price">$347</strike>
          <strong> $97 </strong> Lifetime Software
        </div>
        <CardPaypalSubEmails monthly={true} amount={97} />
      </div>
    </div>
  );
}

export default withRouter(CardFormPaypalSubEmailsToggle);
