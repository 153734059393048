import React, { useState, useEffect, useContext } from "react";
import SailorContent from "../store/SailorContext";
import Detects from "./Detects";
import MainNavigation from "./MainNavigation";
import CheckJWTer from "../hooks/useCheckJWT";

var mock_results = [
  {
    fake_prob: "96%",
    body: "This is the content that is absolutely made by ChatGPT which is fairly easy to detect",
  },
  {
    fake_prob: "97%",
    body: "You'll get access to the new and improved detector created by true AI MIT researchers",
  },
];

//fields: domain, url, title, content, rd, traffic, da, dr, obl (in that order)

const DetectionLog = (props) => {
  const context = useContext(SailorContent);

  const [currentPage, setCurrentPage] = useState(0);
  const [wResults, setWResults] = useState([]);

  const [errorMessage, setErrorMessage] = useState("...");

  const [data] = CheckJWTer();

  const resulter = () => {
    const z = mock_results.map((arr) => <Detects fake_prob={arr.fake_prob} body={arr.body} />);
    setWResults(z);
  };

  const search_req = (skip) => {
    console.log("current page: " + skip);
    setWResults([<div className="lds-hourglass"></div>]);
    (async () => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 15000);
      try {
        const rx = await fetch(`${context.api_url}GenerateText/detections-extension/?skip=${skip * 50}`, {
          method: "get",
          signal: controller.signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${context.jwt}`,
          },
        }).then((res) => res.json());
        console.log(rx);
        if (rx.length > 0) {
          mock_results = rx;
          setErrorMessage("...");
          resulter();
        } else {
          setErrorMessage("You might need to subscribe to the pro membership or start detecting with the chrome extension if you already have it");
          setWResults([<div></div>]);
        }
      } catch (error) {
        console.error(error);
        setWResults([<div></div>]);
        setErrorMessage("No results, or server caught fire, try again");
      } finally {
        clearTimeout(timeoutId);
      }
    })();
  };

  function prev_click() {
    setWResults([<div className="lds-hourglass"></div>]);
    setErrorMessage("...");
    if (currentPage <= 0) {
      setCurrentPage(0);
      search_req(0);
      setErrorMessage("Can't go below page 0, showing results for page 0 (1)");
      return null;
    }
    var cur_page = currentPage;
    cur_page--;
    setCurrentPage(cur_page);
    search_req(cur_page);
  }
  function next_click() {
    setWResults([<div className="lds-hourglass"></div>]);
    setErrorMessage("...");
    var cur_page = currentPage;
    cur_page++;
    setCurrentPage(cur_page);
    search_req(cur_page);
  }

  useEffect(() => {
    resulter();
    search_req(0);
  }, []);

  return (
    <div>
      <MainNavigation current="Detector Logs" />
      <div className="detection-logs-parent">
        <div className="separator"></div>
        <div className="indicators">
          <div className="ind-domain">{"Score"}</div>
          <div className="ind-url">{"Content (Click to copy to clipboard)"}</div>
        </div>
        <div className="search-results-parent">
          <div>{wResults}</div>
        </div>
        <div className="previous-next">
          <button className="nav-button" onClick={prev_click}>
            Prev
          </button>
          <div className="current-page">{currentPage}</div>
          <button className="nav-button" onClick={next_click}>
            Next
          </button>
        </div>
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  );
};

export default DetectionLog;
