import "./css/BulkDetector.css";
import React, { useState, useEffect, useContext } from "react";
import Tooltip from "./Tooltip";

function copyDivToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export default function BulkDetector({ values }) {
  let arr = values;
  let final_string = `${(arr.real_probability * 100).toFixed(2)}% Real | ${arr.url.substring(0, 50)}`;
  let final_class = `${(arr.real_probability * 100).toFixed(2)}%`;
  let classer = "progressbar-div";
  if (arr.real_probability === 0 && arr.fake_probability === 0) {
    final_string = `Pending... | ${arr.url.substring(0, 50)}`;
    final_class = `100%`;
    classer = "progressbar-div-pending";
  }

  let tooltip = `Click the floppy disk icon to copy the full url to your clipboard. Full URL: ${arr.url}`;

  const copyResults = () => {
    try {
      copyDivToClipboard(values.url);
    } catch (error) { }
  };
  //   let final_class = `${arr.fake_probability > 0.49 ? "kwc-clicked-kw-incomplete" : "kwc-clicked-kw"}`;
  //   if (arr.real_probability > arr.fake_probability) {
  //     final_string = `${(arr.real_probability * 100).toFixed(2)}% Real | ${arr.url.substring(0, 50)}`;
  //   } else {
  //     final_string = `${(arr.fake_probability * 100).toFixed(2)}% Fake | ${arr.url.substring(0, 50)}`;
  //   }
  return (
    <div className="progressbar">
      <div className={classer} style={{ width: `${final_class}` }}>
        <div className="progress-label">
          {final_string}{" "}
          <button className="copy-url-detector" onClick={copyResults}>
            💾
          </button>
        </div>
      </div>
    </div>
  );
}
