import React, { useEffect, useState, useContext } from "react";
import "./css/PickProject.css";
import MainNavigation from "./MainNavigation";
import Project from "./Project";
import NewProject from "./NewProject";
import SailorContent from "../store/SailorContext";
import CheckJWTer from "../hooks/useCheckJWT";

function PullProjs(context) {
  let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + `SailorMonolithic/GetProjects`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "localhost",
          Authorization: `Bearer  ${tokener.token}`,
        },
        mode: "cors",
      });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject("couldn't pull projects");
      }
    } catch (ex) {
      console.log("couldn't pull projects");
      console.log(ex);
      reject("couldn't pull projects");
    }
  });
  return promise;
}

function PickProject() {
  const context = useContext(SailorContent);
  const [contents, setContents] = useState(null);
  const [data] = CheckJWTer();

  const newProjHandler = (newProj) => {
    setContents(context.contents.map((proj) => <Project title={proj.title} keywords={proj.avg_keyword_counters.length} id={proj.id} key={proj.id} />));
  };

  const pull_projs = () => {
    const q = PullProjs(context);
    q.then((resultx) => {
      // context.contents = [];
      for (let proj of resultx) {
        context.contents.unshift(proj);
      }
      // context.contents = resultx;
      setContents(context.contents.map((proj) => <Project title={proj.title} keywords={proj.avg_keyword_counters.length} id={proj.id} key={proj.id} />));
    }).catch((whattodo) => {
      console.log(whattodo);
    });
  }

  useEffect(() => {
    console.log(context, "context from useeffect");
    let resulter = context.user_id;
    if (resulter != null) {
      //clean the projects, otherwise every refresh they keep adding on to each other
      context.contents = context.contents.filter((obj) => {
        return obj.id === "1" || obj.id === "2";
      });

      pull_projs();
    }
  }, []);

  return (
    <div>
      <MainNavigation current="Content Optimizer" />
      <div className="pick-projects-parent">
        <NewProject newProj={newProjHandler} pullps={pull_projs} />
        <div className="description">
          Type a keyword and our AI will optimize your content. <div className="compare-credit compare-instructions"><a href="https://kazanseo.com/blog/kazan-seo-content-optimizer-how-it-works/" target="_blank" rel="noreferrer">Full Guide</a></div>
        </div>
        <div className="pick-projects-title">Content Optimizer Projects Here:</div>
        <div className="pick-projects-bar"></div>
        <div className="pick-projects-projects">{contents}</div>
      </div>
    </div>
  );
}

export default PickProject;
