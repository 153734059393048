import React, { useState } from 'react';
import './css/ProjectForm.css';


function ProjectForm(props) {

    const [enteredTitle, setEnteredTitle] = useState('');
    const [projSubmitted, setProjSubmitted] = useState(false);

    const titleChangeHandler = (event) => {
        setEnteredTitle(event.target.value);
    };

    const submitHandler = (event) => {
        //this prevents the page from being reloaded after submitting the form
        event.preventDefault();
        props.onStartProject(enteredTitle);
        setProjSubmitted(true);
    };

    return (
        <form className="project-form-form" onSubmit={submitHandler}>
            <div className="project-form-top">
                <div className="project-form-title">
                    <label>Keyword To Optimize (Project Name)</label>
                    <input type="text" value={enteredTitle} onChange={titleChangeHandler} />
                </div>
            </div>
            <div className="project-form-actions">
                <button type="button" onClick={props.onCancel}>Cancel</button>
                <button type="submit">Add Keyword</button>
            </div>
        </form>
    );

}

export default ProjectForm;