import "./css/Detector.css";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Extensions from "../Extensions";
import ClickBankAds from "./ClickBankAds";
import Tooltip from "./Tooltip";
import CheckJWTer from "../hooks/useCheckJWT";

let ce = null;

function Detector(props) {
  // const [balance, setBalance] = useState("pulling balance...");
  const [total_detections, settotal_detections] = useState("Detect once to figure out");
  const [dec_content, setDect_content] = useState("");
  const [initial_content, setInitial_content] = useState("");
  const [maxTokens, setMaxTokens] = useState(0);
  const [real, setReal] = useState("50.00%");
  const [realWidth, setRealWidth] = useState("50");
  const [fake, setFake] = useState("50.00%");
  const [detecting, setDetecting] = useState(false);
  const [detect_errors, setDetect_errors] = useState("");
  const [final_result, setFinal_result] = useState("");
  const [final_css, setFinal_css] = useState("");
  const [balance, setBalance] = useState("");
  const [bulk, setBulk] = useState(true);
  const context = useContext(SailorContent);
  const history = useHistory();
  const [data] = CheckJWTer();
  const childFunc = React.useRef(null);

  const checker_keys = (arr, target) => target.every((v) => arr.includes(v));

  //this captures the paste event and only pastes the raw plain text
  useEffect(() => {
    ce = document.querySelector("#text-boxer");
    ce.addEventListener("paste", function (e) {
      e.preventDefault();
      var text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    });
  }, [context]);

  const generatorContentHandler = (event) => {
    event.preventDefault();
    // setDect_content(event.target.innerText.replace(/[\r\n]/gm, "").replace(/['"]+/g, ""));
    setDect_content(event.target.innerText.replace(/[\r\n]/gm, ""));
    // setDect_content(event.target.innerText);
  };

  const addNewContent = (dect) => {
    setMaxTokens(dect.used_tokens);
    setReal(`${(dect.real_probability * 100).toFixed(2)}%`);
    setRealWidth(`${(dect.real_probability * 100).toFixed(0)}`);
    setFake(`${(dect.fake_probability * 100).toFixed(2)}%`);
    settotal_detections(dect.total_detections);
    if (dect.real_probability > dect.fake_probability) {
      setFinal_result(`probably ${(dect.real_probability * 100).toFixed(2)}% Real`);
      console.log(final_result);
      setFinal_css("final-result-real");
    } else if (dect.real_probability < dect.fake_probability) {
      setFinal_result(`probably ${(dect.fake_probability * 100).toFixed(2)}% Fake`);
      console.log(final_result);
      setFinal_css("final-result-fake");
    } else {
      setFinal_result();
      setFinal_css();
    }
    console.log(dect.encoded);
  };

  const detectHandler = () => {
    if (detecting) {
      return;
    }
    //the childFunc thing is to move to another clickbank ad
    childFunc.current();
    setDetecting(true);
    let generation = {
      detect: dec_content,
    };
    fetch(context.api_url + "GenerateText/detector", {
      method: "post",
      body: JSON.stringify(generation),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        //set here the new text generated as well as the new balance
        const keys = Object.keys(rx);
        if (checker_keys(keys, ["all_tokens", "used_tokens", "real_probability", "fake_probability", "total_detections", "encoded"])) {
          addNewContent(rx);
        } else {
          setDetect_errors("servers caught fire, thank you, try again");
        }
        setDetecting(false);
      })
      .catch(() => {
        setDetecting(false);
        setDetect_errors("servers caught fire, thank you, try again");
      });
  };

  let bulk_button = <button onClick={() => history.push("/bulk-detector")}>Bulk Detector</button>;

  return (
    <div>
      <MainNavigation current="AI Detector Small" />
      <div className="generator">
        <div id="text-boxer" className="text-boxer" contentEditable="true" suppressContentEditableWarning onInput={generatorContentHandler}>
          {/* {initial_content} */}
        </div>
        <div className="detector-info">
          {bulk === true ? bulk_button : null}
          <h3>Total Detections:</h3>
          <div className="gen-balance">{total_detections}</div>
          <div className="gen-text">
            <span className="emoji-hand">←</span> Paste Text to detect here. The longer the content the more accurate the detection, 200+ words <div className="compare-credit compare-instructions"><a href="https://kazanseo.com/blog/kazanseo-ai-gpt-x-detector-how-it-works/" target="_blank" rel="noreferrer">How it works</a></div>
          </div>
          <h4>{balance}</h4>
          <h2 className="ext-link-h2">
            <a
              className="ext-link"
              href="https://chrome.google.com/webstore/detail/kazanseo-ai-content-detec/lfijlkidolonkfhjehohinofjdgfneah"
              rel="noreferrer"
              target="_blank">
              Get the Extension Here
            </a>
          </h2>
          <ClickBankAds childFunc={childFunc} />
          <div className={final_css}>
            <div>{final_result}</div>
          </div>
          <div className="detector-container">
            <div className="detector-bars">
              <tr className="bar-row" id="top-table">
                <td id="real">Real</td>
                <td id="dtct-message">Prediction based on {maxTokens} tokens</td>
                <td id="fake">Fake</td>
              </tr>
              <tr className="bar-row">
                <td id="real-percentage">{real}</td>
                <td id="bar-container">
                  <div id="bar" style={{ width: `${realWidth}%` }}></div>
                </td>
                <td id="fake-percentage">{fake}</td>
              </tr>
            </div>
            <button onClick={detectHandler}>{detecting ? "DETECTING..." : "DETECT"}</button>
            <div className="error-box">
              <div>{detect_errors}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detector;
