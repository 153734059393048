import "./css/ClickBankAds.css";
import React, { useState, useEffect, useContext } from "react";
import SailorContent from "../store/SailorContext";

let it = 0;
let id = 0;
let iu = 0;

let obj_index = 0;

let txt = [
  {
    url: "https://bit.ly/3j1MYBa",
    anchor: "Click to get them",
    title: "Real Website Outreach Links - Ranking Quality",
    description:
      "Are your rankings stalling? Or even worse, your sites tanked in recent algo update? ... We got the solution",
  },
  {
    url: "https://bit.ly/3j1MYBa",
    anchor: "Click to get in",
    title: "Hyper Link Matrix - Balancing Link Juice",
    description: "Don't know what to do with your ranks? Are they not moving or even losing positions? ... We'll fix it for you",
  },
]; /* The text */

let speed = 8; /* The speed/duration of the effect in milliseconds */
// let docL = 1;

const typeWriterTitle = () => {
  if (it < txt[obj_index].title.length) {
    try {
      document.getElementById("cb-title").innerHTML += txt[obj_index].title.charAt(it);
    } catch { }
    // docL.scrollTop = docL.scrollHeight;
    it++;
    setTimeout(typeWriterTitle, speed);
  } else {
    it = 0;
  }
};
const typeWriterDesc = () => {
  if (id < txt[obj_index].description.length) {
    try {
      document.getElementById("cb-desc").innerHTML += txt[obj_index].description.charAt(id);
    } catch { }
    // docL.scrollTop = docL.scrollHeight;
    id++;
    setTimeout(typeWriterDesc, speed);
  } else {
    id = 0;
  }
};
const typeWriterLink = () => {
  if (iu < txt[obj_index].anchor.length) {
    try {
      document.getElementById("cb-link").innerHTML += txt[obj_index].anchor.charAt(iu);
    } catch { }
    // docL.scrollTop = docL.scrollHeight;
    iu++;
    setTimeout(typeWriterLink, speed);
  } else {
    iu = 0;
  }
};

const fireCompletion = (ind) => {
  document.getElementById("cb-title").innerHTML = "";
  document.getElementById("cb-desc").innerHTML = "";
  document.getElementById("cb-link").innerHTML = "";
  typeWriterTitle(ind);
  typeWriterDesc(ind);
  typeWriterLink(ind);
};

function ClickBankAds({ childFunc }) {
  const [cbUrl, setCbUrl] = useState(txt[0].url);
  const context = useContext(SailorContent);

  //   console.log(txt.length);

  const changePrevLink = () => {
    if (obj_index <= 0) {
      obj_index = txt.length - 1;
    } else {
      obj_index -= 1;
    }
    setCbUrl(txt[obj_index].url);
    fireCompletion(obj_index);
  };
  const changeNextLink = () => {
    if (obj_index >= txt.length - 1) {
      obj_index = 0;
    } else {
      obj_index += 1;
    }
    setCbUrl(txt[obj_index].url);
    fireCompletion(obj_index);
  };

  useEffect(() => {
    obj_index = 0;
    setCbUrl(txt[obj_index].url);
    fireCompletion(obj_index);
  }, []);

  useEffect(() => {
    childFunc.current = changeNextLink;
  }, []);

  return (
    <div className="cb">
      <div className="cb-left-arrow" onClick={changePrevLink}>
        <i className="cb-arrow cb-left"></i>
      </div>
      <div className="cb-center">
        <div className="cb-title" id="cb-title"></div>
        <div className="cb-description" id="cb-desc"></div>
        <a className="cb-link" id="cb-link" href={cbUrl} target="_blank" rel="noreferrer">
          {" "}
        </a>
      </div>
      <div className="cb-right-arrow" onClick={changeNextLink}>
        <i className="cb-arrow cb-right"></i>
      </div>
    </div>
  );
}

export default ClickBankAds;
