import React from "react";

const SailorContent = React.createContext({
  user_id: "",
  jwt: "",
  isLoggedIn: false,
  api_url: "https://kazanseo.com/api2/",
  // api_url: "https://kazan.dev/",
  // api_url_dev: "https://localhost:44348/",
  // api_url: "https://localhost:44348/",
  //test here and there
  contents: [
    {
      id: "1",
      title: "Dummy Project",
      user_id: "asasdasdadsa",
      avg_total_words: 1200,
      avg_keyword_counters: [
        { keyword: "white hat seo", avg: 12 },
        { keyword: "black hat seo", avg: 15 },
        { keyword: "grey hat seo", avg: 10 },
        { keyword: "Matt Cutts", avg: 75 },
      ],
      content: "this is my current white hat seo content right here right now",
    },
    {
      id: "2",
      title: "Dummy Project 2",
      user_id: "asasdasdadsa",
      avg_total_words: 2200,
      avg_keyword_counters: [
        { keyword: "Who is John Mueller", avg: 12 },
        { keyword: "Why did my site tank", avg: 12 },
        { keyword: "Core Algo update", avg: 12 },
        { keyword: "lost all my rankings", avg: 12 },
        { keyword: "Mcdonalds jobs", avg: 12 },
      ],
      content: "this is my current seo content right here right now",
    },
  ],
  url_extractor: [
    {
      _id: "This shall be a very weird dummy project",
      user_id: "12312313",
      urls: [{ url: "https://google.com", text: "Google doesn't like us :(" }],
      date: "12/4/2021 7:05:09 PM",
    },
  ],
  kwc_projects: [
    {
      id: "61ff0de2af896191bf963224",
      user_id: "12312313",
      done: "true",
      seed: "dummy keyword",
      layer_1_count: 1,
      layer_2_count: 2,
      layer_3_count: 3,
    },
  ],
  kwc_bulk_projects: [
    {
      id: "61ff0de2af896191bf963221",
      user_id: "12312313",
      done: "true",
      seed: "how to eat sushi",
      god_layer_count: 2,
    },
  ],
  detector_bulk_projects: [
    {
      _id: "61ff0de2af896191bf963221",
      user_id: "12312313",
      done: true,
      seed: "fakedomain.ai",
      urls_done: 4,
      urls: [
        {
          all_tokens: 0,
          used_tokens: 0,
          real_probability: 1,
          fake_probability: 0,
          total_detections: 0,
          encoded: null,
          url: "https://kazanseo.com/textron",
        },
        {
          all_tokens: 0,
          used_tokens: 0,
          real_probability: 0.3,
          fake_probability: 0.7,
          total_detections: 0,
          encoded: null,
          url: "https://copy.ai",
        },
        {
          all_tokens: 0,
          used_tokens: 0,
          real_probability: 0.09,
          fake_probability: 0.91,
          total_detections: 0,
          encoded: null,
          url: "https://jasper.ai/",
        },
        {
          all_tokens: 0,
          used_tokens: 0,
          real_probability: 0.07,
          fake_probability: 0.93,
          total_detections: 0,
          encoded: null,
          url: "https://closerscopy.com/",
        },
        {
          all_tokens: 0,
          used_tokens: 0,
          real_probability: 0.05,
          fake_probability: 0.95,
          total_detections: 0,
          encoded: null,
          url: "https://rytr.me/",
        },
      ],
    },
  ],
  keyword_parenting: [
    {
      _id: "6307eb90d7143119fabb3283",
      user_id: "62144f6e846b73648015d917",
      kws: [
        "bitcoin trading",
        "bitcoin trading bot",
        "bitcoin trading exchange",
        "bitcoin trading taxes",
        "bitcoin bot",
        "bitcoin bot windows",
      ],
      parenting: [
        [
          "bitcoin trading",
          "bitcoin trading bot",
          "bitcoin trading taxes",
          "bitcoin trading exchange",
        ],
        ["bitcoin bot", "bitcoin trading bot", "bitcoin bot windows"],
        [
          "bitcoin",
          "bitcoin bot",
          "bitcoin trading",
          "bitcoin bot windows",
          "bitcoin trading taxes",
          "bitcoin trading exchange",
        ],
      ],
    },
  ],
  active_sub: false,
});

export default SailorContent;
