import React, { useState, useEffect } from "react";
import "./css/DetectionLogs.css";
import facebook from "../media/facebook.svg";
import instagram from "../media/instagram.svg";
import tiktok from "../media/tiktok.svg";
import twitter from "../media/twitter.svg";
import youtube from "../media/youtube.svg";
function copyDivToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

function ResultsEmails(props) {
  const [openMenu, setOpenMenu] = useState(false);
  const [bodier, setbodier] = useState(props.item);
  const [trimmedIcon, setTrimmedIcon] = useState(props.item);

  const copyContent = () => {
    try {
      copyDivToClipboard(props.item);
      let cz = (
        <div className="social-result">
          <div>copied...</div>
        </div>
      );
      setbodier(cz);
      // console.log("printing trimmedIcon");
      // console.log(trimmedIcon);
      setTimeout(() => {
        setbodier(trimmedIcon);
      }, 1000);
    } catch (error) { }
  };

  const trim = (str, chars) => str.split(chars).filter(Boolean).join(chars);

  // console.log(props);

  const icon_select = (url) => {
    let trim_url = url.replaceAll("https://", "").replaceAll("http://", "").replaceAll("www.", "");

    let final_icon = "";
    let final_url = "";

    if (trim_url.includes("instagram")) {
      final_url = trim_url.replaceAll("instagram.com/", "");
      final_icon = instagram;
    } else if (trim_url.includes("facebook")) {
      final_url = trim_url.replaceAll("facebook.com/", "");
      final_icon = facebook;
    } else if (trim_url.includes("tiktok")) {
      final_url = trim_url.replaceAll("tiktok.com/", "");
      final_icon = tiktok;
    } else if (trim_url.includes("twitter")) {
      final_url = trim_url.replaceAll("twitter.com/", "");
      final_icon = twitter;
    } else if (trim_url.includes("youtube")) {
      final_url = trim_url.replaceAll("youtube.com/", "");
      final_icon = youtube;
    } else {
      final_url = trim_url;
    }

    final_url = trim(final_url, "/");

    let z = (
      <div className="social-result">
        <img src={final_icon} className="social-icon" />
        <div>{final_url.substring(0, 70)}</div>
      </div>
    );

    return z;
  };

  const renderer = () => {
    if (props.type === "socials-log") {
      let y = icon_select(props.item);
      setTrimmedIcon(y);
      setbodier(y);
    } else {
      let y = icon_select(props.item);
      setTrimmedIcon(y);
      setbodier(y);
    }
  };

  useEffect(() => {
    renderer();
  }, []);

  return (
    <div className="Results-main">
      <div className={`${props.type}`} onClick={copyContent}>
        {bodier}
      </div>
    </div>
  );
}

export default ResultsEmails;
