import React, { useState } from "react";
import "./css/DetectionLogs.css";
function copyDivToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}
function Results(props) {
  const [score, setscore] = useState(props.fake_prob);
  const [openMenu, setOpenMenu] = useState(false);
  const [bodier, setbodier] = useState(props.body);

  const copyContent = () => {
    try {
      copyDivToClipboard(props.body);
      setbodier("copied...");
      setTimeout(() => {
        setbodier(props.body);
      }, 1000);
    } catch (error) { }
  };

  const show_content = () => {
    //this is a pop up that displays content
    // console.log("showing content");
    setOpenMenu(true);
  };
  const hide_content = () => {
    //this is a pop up that displays content
    // console.log("hiding content");
    setOpenMenu(false);
  };
  return (
    <div className="Results-main">
      <div className="domain">{`${(score * 100).toFixed(2)}% AI Content`}</div>
      <div className={`title ${openMenu ? "clicked-cont" : ""}`} onClick={copyContent} onMouseOver={show_content} onMouseLeave={hide_content}>
        {bodier.substring(0, 50)}
      </div>
      <div className={`cont-cont ${openMenu ? "opend" : ""}`} onMouseEnter={show_content} onMouseLeave={hide_content}>
        {`${"---- CONTENT: \n\n" + props.body}`}
      </div>
    </div>
  );
}

export default Results;
