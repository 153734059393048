import "./css/BulkDetector.css";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Extensions from "../Extensions";
import BulkDetectorProject from "./BulkDetectorProject";
import BulkDetectorUrl from "./BulkDetectorUrl";

let project = "";

function BulkDetector(props) {
  const [detects, setdetects] = useState("");
  const [urls_typed, seturls_typed] = useState(" ");
  const [projects_detector, set_projects_detector] = useState("");
  const [projectS, setProjectS] = useState("");
  const [errorMesage, setErrorMessage] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [detectButton, setDetectButton] = useState("Detect Urls for AI Content");
  const context = useContext(SailorContent);

  const checker_keys = (arr, target) => target.every((v) => arr.includes(v));

  const add_detector = () => {
    if (detects.length > 200) {
      //   alert("Woa, too many urls! We process 200 per batch, chill out");
      setErrorMessage("Woa, too many urls! We process 200 per batch, chill out, try again");
      return;
    }
    seturls_typed("");
    setErrorMessage("");
    let payload = { list_urls: detects };
    fetch(context.api_url + "GenerateText/bulkdetector", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("_id")) {
          map_detector_bulk_projects();
          refresh_detects();
        } else {
        }
      })
      .catch(() => { });
  };

  const pull_1_dect = (id) => {
    // fetch(context.api_url + "detects/PullSpecificKWCBulk/?project_id=" + id, {
    //   method: "get",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer  ${context.jwt}`,
    //     Origin: "",
    //     Host: "localhost",
    //   },
    // })
    //   .then((r) => {
    //     return r.json();
    //   })
    //   .then((rx) => {
    //     console.log(rx);
    //     project = rx;
    //     map_detects(rx);
    //   })
    //   .catch(() => {
    //     set_projects_detector(<div>Something messed up, please pull/refresh projects again</div>);
    //   });

    let foundIndex = context.detector_bulk_projects.findIndex((x) => x._id === id);
    if (foundIndex >= 0) {
      project = context.detector_bulk_projects[foundIndex];
      map_detects(project);
    }
  };

  const map_detects = (rx) => {
    // setProjectS(rx);
    const keys = Object.keys(rx);
    if (checker_keys(keys, ["_id", "user_id", "done", "seed", "urls"])) {
      let temp = rx.urls;
      console.log("logging all urls when mapping a full project");
      console.log(temp);
      let accumulative_score = 0;
      let num_detected = 0;
      for (let realness of temp) {
        if (realness.real_probability + realness.fake_probability !== 0) {
          accumulative_score += realness.real_probability;
          num_detected++;
        }
      }

      let final_score = accumulative_score / num_detected;
      let final_decision = `${(final_score * 100).toFixed(2)}% Real`;
      if (final_score >= 0.49) {
        final_decision = `${(final_score * 100).toFixed(2)}% Real`;
      } else {
        final_decision = `${100 - (final_score * 100).toFixed(2)}% Fake`;
      }
      console.log(accumulative_score);
      console.log(num_detected);
      console.log(temp.length);
      console.log(final_score);
      console.log(final_decision);

      set_projects_detector(
        <div className="kwc-clcked-project">
          <div className="kwc-clicked-done">Done: {rx.done || rx.done === true ? "yes" : "no"}</div>
          <div className="kwc-clicked-seed">Seed: {rx.seed}</div>
          <div className="kwc-clicked-seed">Veredict: {final_decision}</div>
          <div>Click the floppy disk icon to copy the FULL URL to your clipboard</div>
          <div>
            {/* {temp.map((arr) => (
              <div className={`${arr.fake_probability > 0.49 ? "kwc-clicked-kw-incomplete" : "kwc-clicked-kw"}`}>{arr.url.substring(0, 50)}</div>
            ))} */}
            {temp.map((arr) => (
              <BulkDetectorUrl values={arr} />
            ))}
          </div>
        </div>
      );
      //   else {
      //     let lt_1 = rx.god_layer.map((arr) => arr);
      //     let temp = lt_1;

      //     set_projects_detector(
      //       <div className="kwc-clcked-project">
      //         <div className="kwc-clicked-done">Done: {rx.done || rx.done === "true" ? "yes" : "no"}</div>
      //         <div className="kwc-clicked-seed">Seed: {rx.seed}</div>
      //         <div>
      //           {temp.map((arr) => (
      //             <div className="kwc-clicked-kw">{arr}</div>
      //           ))}
      //         </div>
      //       </div>
      //     );
      //   }
    } else {
      set_projects_detector(<div>Something messed up, please pull/refresh projects again</div>);
    }
  };

  const map_detector_bulk_projects = () => {
    let z = context.detector_bulk_projects.map((arr) => (
      <BulkDetectorProject
        key={arr._id}
        id={arr._id}
        user_id={arr.user_id}
        done={arr.done}
        seed={arr.seed.substring(0, 23)}
        god_layer={arr.urls_done}
        selection={click_on_dect_proj}
      />
    ));
    set_projects_detector(<div className="kwc-projects">{z}</div>);
  };

  const refresh_detects = () => {
    fetch(context.api_url + "GenerateText/getdetectorprojects", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
      mode: "cors",
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        console.log(rx);
        if (rx.hasOwnProperty("projs") && rx.projs != null) {
          for (let proj of rx.projs) {
            //only add projects that don't already exist with the same id
            if (context.detector_bulk_projects.filter((e) => e._id === proj._id).length === 0) {
              context.detector_bulk_projects.unshift(proj);
            }
            let foundIndex = context.detector_bulk_projects.findIndex((x) => x._id === proj._id);
            context.detector_bulk_projects[foundIndex] = proj;
            context.detector_bulk_projects[foundIndex].urls_done = proj.urls.length;
            context.detector_bulk_projects[foundIndex].done = proj.done === true ? "Yes" : "Processing...";
          }
        }
        map_detector_bulk_projects();
        // set_projects_kwc(JSON.parse(rx));
      })
      .catch((error) => {
        console.log(error);
        map_detector_bulk_projects();
      });
  };

  const click_on_dect_proj = (thing) => {
    //pull project here with http
    pull_1_dect(thing);
  };

  useEffect(() => {
    try {
      refresh_detects();
    } catch (ex) {
      return;
    }
  }, []);

  useEffect(() => {
    map_detector_bulk_projects();
  }, [context.detector_bulk_projects]);

  const submitHandler = (event) => {
    event.preventDefault();
    if (detectButton === "Processing...") {
      return;
    }
    setDetectButton("Processing...");
    setTimeout(() => {
      setDetectButton("Detect Urls for AI Content");
      setErrorMessage("");
      console.log("Bulk Detect Button Activated Again");
    }, 3000);
    try {
      add_detector();
      refresh_detects();
    } catch (ex) {
      return;
    }
  };

  const inputChangeHandler = (event) => {
    const regex = /(?:\r\n|\r|\n)/g;
    // let cleaned_kwc = event.target.value.replace(regex, ",");
    let cleaned_dts = event.target.innerText.replace(regex, ",").split(",");
    setdetects(cleaned_dts);
    // seturls_typed(event.target.innerText);
  };

  const refreshProjectsHandler = (event) => {
    refresh_detects();
  };

  return (
    <div>
      <MainNavigation current="Bulk AI Detector" />
      <div className="kwc-wrapper">
        <div className="kwclusters-bulk">
          <div>
            <div className="kwc-info">Input Urls to DETECT Separated by Comma or New Line</div>
            <div className="bulk-keyworder" contentEditable="true" suppressContentEditableWarning onInput={inputChangeHandler}>
              {urls_typed}
            </div>
            {/* <input type="text" value={urls_typed} onChange={inputChangeHandler} /> */}
            <div className="kwc-buttons">
              <button onClick={submitHandler}>
                <span className="emoji-big-d">🔬 </span>
                {detectButton}
              </button>
              <button onClick={refreshProjectsHandler}>
                <span className="emoji-big-d">📚</span> View Projects
              </button>
            </div>
            <div className={errorMesage === "" ? "compare-credit detector-alerts-blank" : "compare-credit detector-alerts"}>{errorMesage}</div>
            <div className="compare-credit">
              If you input just 1 single domain like domain.com without "http/https" then the tool will pull the top 100 results for that domain from google and
              detect those pages.
            </div>
            <div className="compare-credit">
              Click "View projects" to pull previous projects. If the result is red, the url is still pending, green is done.
            </div>
          </div>
          <div className="kwc-bar-separator"></div>
        </div>
        <div>{projects_detector}</div>
      </div>
    </div>
  );
}

export default BulkDetector;
