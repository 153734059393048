import React, { useState, useEffect, useContext } from "react";
import "./css/Refill.css";
import { withRouter, useHistory } from "react-router-dom";
// import CardFormPaypalSub from "./CardPaypalSub";
import CardFormPaypalSubEmailsToggle from "./CardPaypalSubEmailsToggle";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import logo from "../media/logo.svg";

function EmailCreatedAccount(props) {
  const [balance, setBalance] = useState("pulling active sub...");
  const [email, setEmail] = useState("");
  const context = useContext(SailorContent);
  const [emailL, setEmailL] = useState("");

  const [show_paypal, set_show_paypal] = useState("");

  const emailChangeHandler = (event) => {
    setEmailL(event.target.value);
    console.log(emailL);
  };

  const bal_response = () => {
    let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
    if (tokener.token !== null) {
      context.jwt = tokener.token;
    }
    fetch(context.api_url + "Emails/pull-sub", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${tokener.token}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("error") && rx.error === "not verified") {
          setBalance(
            <div className="bad-result">
              Please verify your account to access the free tier, all you have
              to do is click on the link we sent to your email.
            </div>
          );
          set_show_paypal(<CardFormPaypalSubEmailsToggle />);
          return;
        }
        if (!rx.hasOwnProperty("subq")) {
          console.log(rx);
          setBalance("error pulling the sub, sorry :( try again later");
          set_show_paypal(<CardFormPaypalSubEmailsToggle />);
          // set_show_paypal("thank you for being awesome, get the extension above");
          return;
        }
        if (rx.subq === "true") {
          setBalance(
            `Last Subbed ${rx.last_payment} You're good to go! thank you!`
          );
          set_show_paypal(
            "thank you for being awesome, start hunting for emails"
          );
        } else if (rx.subq === "never susbcribed") {
          setBalance(
            <div>
              <div>You're not a member yet</div>
              <div className="sign-up-form-title">
                Get the Lifetime Deal Below For Insane Email Sniping and Maximum Outreaching
              </div>
            </div>
          );
          set_show_paypal(<CardFormPaypalSubEmailsToggle />);
          // set_show_paypal("thank you for being awesome, get the extension above");
        } else if (rx.last_payment === "expired subscription") {
          setBalance(
            "Expired Subscription, please renew to access the unlimited email finder"
          );
          set_show_paypal(<CardFormPaypalSubEmailsToggle />);
          // set_show_paypal("thank you for being awesome, get the extension above");
        } else {
          setBalance("error pulling the sub, sorry :( try again later");
          set_show_paypal(<CardFormPaypalSubEmailsToggle />);
        }
      })
      .catch(() => {
        setBalance("Sign Up Now for the full experience");
        // set_show_paypal(<CardFormPaypalSub />);
      });
  };

  useEffect(() => {
    try {
      bal_response();
    } catch (ex) {
      setBalance("pulling sub failed");
      return;
    }
  }, []);

  return (
    <div>
      <MainNavigation current="Email Sniper Subscribe" />
      <div className="refill">
        <div className="slidecontainer">
          <div className="refill-square">
            <div className="center-kazano">
              <img src={logo} alt="kazano" className="login-kazano-r" />
            </div>
            <h3 className="h3-sub">Hey! {email}</h3>
            <p className="gray-p">
              Thank you for joining Kazan Email Sniper, play around with it and upgrade if you feel the need to!
            </p>
            <h3>Upgrade to Pro and get:</h3>
            <p className="gray-p">
              <span className="emoji-color">✔️</span> Unlimited Searches
            </p>
            <p className="gray-p">
              <span className="emoji-color">✔️</span> More than 25 Results in the Search Engine, get ALL of them (thousands)
            </p>
            <p className="gray-p">
              <span className="emoji-color">✔️</span> Export Up to 5000 contacts at once
            </p>
            <p className="gray-p">
              <span className="emoji-color">✔️</span> Email & Contacts Search Engine
            </p>
            <p className="gray-p">
              <span className="emoji-color">✔️</span> Everything else you are already getting
            </p>
          </div>
        </div>
        <div className="signupper">
          <h3>Do you have the email finder for maximum outreach already?</h3>
          <div className="subtitle-exp normal-st sub-margin">
            After signing up you can use the email sniper free tier tool,
            upgrade now to find unlimited contacts
          </div>
          <h4>{balance}</h4>
          {show_paypal === "" ? (
            <div className="landing-form-fields">
              <div className="sign-up-form-title">
                Create an account to access the email finder:
              </div>
              <label>Email</label>
              <input
                type="email"
                value={emailL}
                onChange={emailChangeHandler}
              />
              <label>Password</label>
            </div>
          ) : (
            show_paypal
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailCreatedAccount;
