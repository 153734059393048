import React from 'react';
import './css/Keywords.css';
function Keywords(props) {

  const changeW = () => {
    props.change(props.keyword);
  };
  const unChangeW = () => {
    props.unchange();
  };

  return (
    <div className="keywordMain" onMouseOver={changeW} onMouseOut={unChangeW}>
      <div className="keyword">{props.keyword}</div>
      <div className="current">{props.current}</div>
      <div className="ideal">{props.ideal}</div>
    </div>
  );
}

export default Keywords;