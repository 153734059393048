import React, { useState, useEffect, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./css/Card.css";
import SailorContent from "../store/SailorContext";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

function CardFormPaypalSub() {
  const context = useContext(SailorContent);
  const history = useHistory();
  //thing
  const confirm_sub = () => {
    let payload = {
      done: "yes",
    };
    fetch(context.api_url + "GenerateText/add-temp-update", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        //update some form of label or something
        history.push({
          pathname: "/thankyou",
        });
      })
      .catch(() => {
        console.log("creating sub failed for some reason, please contact us at info@kazanseo.com");
      });
  };
  return (
    <PayPalScriptProvider
      options={{
        "client-id": "Acq6bIwPdUveiehrhrdD3PIkEh3Z_KOrAxHsjQaOZf8ePWyT2jgCPBLq5X5pP5mthRqBG8CEiVMbb1mw",
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}>
      <PayPalButtons
        createSubscription={(data, actions) => {
          return actions.subscription
            .create({
              plan_id: "P-5PN581468A4271846MQJ5FIQ",
              custom_id: context.user_id + "@detectorext", // this will be userid and also email id (or just userid)
            })
            .then((orderId) => {
              return orderId;
            });
        }}
        style={{
          label: "subscribe",
        }}
        onApprove={(data, details) => {
          console.log("it worked");
          console.log(data);
          console.log(details);
          context.active_sub = true;
          confirm_sub();
        }}
        onError={(err) => console.log(err)}
        catchError={(err) => console.log(err)}
        onCancel={(err) => console.log(err)}
      />
    </PayPalScriptProvider>
  );
}

export default withRouter(CardFormPaypalSub);
