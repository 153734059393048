import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import SailorContext from "../store/SailorContext";
import "./css/Login.css";
import logo from "../media/logo.svg";
import kazano from "../media/kazano.png";

function Loginer(email, pass, context) {
  let jsonObject = { email: email, pass: pass };
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + "SailorMonolithic/LoginStandard", {
        method: "post",
        body: JSON.stringify(jsonObject),
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "localhost",
        },
        mode: "cors",
      });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject("login failed");
      }
    } catch (ex) {
      console.log("login failed");
      console.log(ex);
      reject("login failed");
    }
  });
  return promise;
}

function SignUper(email, pass, context) {
  let jsonObject = { email: email, pass: pass };
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + "SailorMonolithic/SignUp", {
        method: "post",
        body: JSON.stringify(jsonObject),
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "localhost",
        },
        mode: "cors",
      });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject("signup failed");
      }
    } catch (ex) {
      console.log("signup failed");
      console.log(ex);
      reject("signup failed");
    }
  });
  return promise;
}

function CheckJWT(context) {
  let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + "SailorMonolithic/CheckJWTToken", {
        method: "post",
        body: JSON.stringify(tokener),
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "localhost",
          Authorization: `Bearer  ${tokener.token}`,
        },
        mode: "cors",
      });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject("couldn't check jwt");
      }
    } catch (ex) {
      console.log("couldn't check jwt");
      console.log(ex);
      reject("couldn't check jwt");
    }
  });
  return promise;
}

function Login(props) {
  const history = useHistory();
  const context = useContext(SailorContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [noticeLabel, setNoticeLabel] = useState("");
  const [login_sign_up, setLogin_SignUp] = useState("Logging In");
  const [button_content, setButton_content] = useState("Log In!");
  const [little_signup_label, setlittle_signup_label] = useState("Sign Up Instead");

  useEffect(() => {
    //check jwt token once
    //if it's valid then redirect to projects
    const q = CheckJWT(context);
    q.then((result) => {
      context.jwt = result.oblastjwttoken;
      context.user_id = result.user_ID;
      console.log("success");
      context.isLoggedIn = true;
      props.onRefresh("");
      history.push({
        pathname: "/projects",
      });
    }).catch((whattodo) => {
      console.log(whattodo);
    });
  }, []);

  const submitFormLoginHandler = () => {
    if (/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(email) === false) {
      console.log("invalid email");
      setNoticeLabel("Invalid Email, Try again");
      return;
    }

    const q = Loginer(email, password, context);
    q.then((result) => {
      context.jwt = result.oblastjwttoken;
      context.user_id = result.user_ID;
      //store the jwt
      localStorage.setItem("potentially_sailor_JWT", result.oblastjwttoken);
      localStorage.setItem("potentially_sailor_UID", result.user_ID);
      console.log("success");
      context.isLoggedIn = true;
      props.onRefresh("");
      history.push({
        pathname: "/projects",
      });
    }).catch((whattodo) => {
      console.log(whattodo);
      setNoticeLabel(whattodo);
    });
  };

  const verifyLabel = () => {
    try {
      setNoticeLabel("Creating your Account, Please click on the email we sent to verify your account...");
      setTimeout(() => {
        setNoticeLabel("");
        history.push({
          pathname: "/projects",
        });
      }, 5000);
    } catch (error) { }
  };

  const submitFormSignUpHandler = () => {
    if (/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(email) === false) {
      console.log("invalid email");
      setNoticeLabel("Invalid Email, Try again");
      return;
    }
    if (email.length < 5) {
      setNoticeLabel("Email has to be longer than 5 characters");
    } else {
      const q = SignUper(email, password, context);
      q.then((result) => {
        if (result.email.includes("Already in Use")) {
          setNoticeLabel("Duplicate User, Try another one");
        } else {
          context.jwt = result.oblastjwttoken;
          context.user_id = result.user_ID;
          //store the jwt
          localStorage.setItem("potentially_sailor_JWT", result.oblastjwttoken);
          localStorage.setItem("potentially_sailor_UID", result.user_ID);
          console.log("success");
          context.isLoggedIn = true;
          props.onRefresh("");
          verifyLabel();
        }
      }).catch((whattodo) => {
        console.log(whattodo);
        setNoticeLabel(whattodo);
      });
    }
  };

  const sign_up_or_log_in_button = (event) => {
    event.preventDefault();
    if (button_content === "Log In!") {
      submitFormLoginHandler();
    }
    else {
      submitFormSignUpHandler();
    }
  };

  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
    setNoticeLabel("");
  };
  const passChangeHandler = (event) => {
    setPassword(event.target.value);
    setNoticeLabel("");
  };

  const button_swapper = () => {
    if (button_content === "Log In!") {
      setButton_content("Sign Up!");
      setLogin_SignUp("Signing Up");
      setlittle_signup_label("Log In Instead");
    } else {
      setButton_content("Log In!");
      setLogin_SignUp("Logging In");
      setlittle_signup_label("Sign Up Instead");
    }
  };

  let content = (
    <form className="login-form" onSubmit={sign_up_or_log_in_button}>
      <img src={logo} className="login-logo" alt="logo" />
      <div className="login-form-top">
        <div className="login-form-fields">
          <label className="status-login">{login_sign_up}</label>
          <label>Email</label>
          <input type="email" value={email} onChange={emailChangeHandler} />
          <label>Password</label>
          <input type="text" value={password} onChange={passChangeHandler} />
        </div>
      </div>
      <div className="login-form-buttons">
        <button type="submit">{button_content}</button>
      </div>
      <div className="pointer-link" onClick={button_swapper}>
        {little_signup_label}
      </div>
      <div className="login-form-warning">
        <a href="/forgot-pass">Forgot Password</a>
      </div>
      <div className="login-form-warning-blue">{noticeLabel}</div>
    </form>
  );

  return (
    <div>
      <div className="gradient-bar">KAZAN SEO</div>
      <div className="login-main">
        <div className="thirty-three-one">
          <div>
            <h1 className="h1-white">Rank your keywords </h1>
            <h1 className="h1-white">in days, <span className="h1-white-reverse">not months!</span></h1>
          </div>
          <div>• Discover, Research, and Cluster Keywords</div>
          <div>• AI Content Optimization</div>
          <div>• SEO Analysis Text Extraction</div>
          <div>• Detect AI Content</div>
          <div>• Outreach Email Finder</div>
          <div>• Email Verifier</div>
          <div>And soon to include:</div>
          <div>*Backlink crawler, organic traffic estimator, backlink generator</div>
          <div className="user-counts-login"><span className="bolding-login">55,000+</span> SEOs improving their rankings</div>
        </div>
        <div className="thirty-three-three">{content}</div>
      </div>
      <div className="front-second-section">
        <div><span className="bolding-second-section">5 hours</span> to do keyword research</div>
        <div><span className="bolding-second-section">6 hours</span> to do content optimization</div>
        <div><span className="bolding-second-section">8 hours</span> to do competitor analysis</div>
        <div><span className="bolding-second-section">2 hours</span> to do content AI detection</div>
        <div><span className="bolding-second-section">40+ hours</span> to do backlink outreaching</div>
        <div><span className="bolding-second-section"> = 61+ hours</span><span className="bolding-second-last-section"> of headaches</span></div>
      </div>
      <div className="front-third-section">↓ but there's a better way</div>
      <div className="front-fourth-section">
        <h1>Supercharge your SEO instantly, <br /> rank faster, make $$$</h1>
        <p>Do keyword research, analyze competitors, optimize your content, scan it for AI, <br />find contacts to outreach for backlinks, all in one place, FAST!</p>
      </div>
      <div className="front-fifth-section">
        <div>
          <h2>Keyword Research:</h2>
          <p>Discover hundreds and thousands of keywords related to your niche with a simple seed keyword, this is great for parent/child content and improving topical relevancy and creating silo pages.</p>
          <h2>Content Optimization:</h2>
          <p>Optimize your SEO content by co-relation with the top 10 results, by giving you keywords to include in your content that the majority of the top 10 results use in theirs. You'll know for a fact which keywords are the ones reponsible for the high rankings of the competition and you can copy those instantly.</p>
          <h2>Content Analysis:</h2>
          <p>Easily extract the content from the pages of your competition to analyze and fine tune yours. Check the changes over-time in your competitors content and react quickly to keep with the freshness algorithm.</p>
          <h2>Detect AI Content:</h2>
          <p>Detect the AI rate of any given piece of content that you purchase or you inspect in someone else's website. Determine if your competition is using AI content to bridge the topical gap. Pair this with our AI detector chrome extension and you'll analyze entire websites easily and quickly.</p>
          <h2>Outreach Email Finder:</h2>
          <p>Backlinks are an essential part of your SEO efforts, nailing the correct backlinks is an artform. We help you with this by bringing you an outreach email finder, simply put the website you'd like to find an email for and we'll pull it from our database instantly.</p>
          <p>While searching for emails you can write an email and send it via your default emailing tool of choice.</p>
          <h2>Email Verifier:</h2>
          <p>Make sure your emails land in the inbox of your prospects and keep your email operation as healthy as possibly by verifying each and every single email in your lists. We keep the same file format you give us.</p>
        </div>
      </div>
      <div className="front-sixth-section">
        <div><span className="bolding-sixth-section">Hey it's me </span>👋</div>
        <div><p>In 2012 I started my SEO journey with nothing but excel and plain ol' google, at the time I thought it was enough... Quickly realized that using tools was the only way to be efficient.</p></div>
        <div><p>The only problem was <span className="bolding-sixth-section">finding these tools</span>, ones that would do the job, ones that would not make up data, ones that were free or at least low cost.</p></div>
        <div><p>With a coding background I decided to create my own tools to help me where other tools were lacking.</p></div>
        <div><p>Overtime, <span className="bolding-sixth-section">Kazan has become a compilation of tools</span> to help myself while doing SEO and growing my portfolio of websites, I hope they can serve you too.</p></div>
        <div><p>See you in the live serps (hopefully not as my competition).</p></div>
      </div>
      <div className="front-seventh-section">
        <h2>Boost your SEO, Rank, Earn!</h2>
        <p>Don't waste time finding tools online, use Kazan and speed up your entire process.</p>
        <div className="front-final-button">
          <button onClick={() => window.scrollTo(0, 0)}>Create Account Now</button>
        </div>
      </div>
    </div >
  );
}

export default Login;
