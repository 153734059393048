import "./css/KeywordCluster.css";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Extensions from "../Extensions";
import BulkKwcProject from "./BulkKwcProject";
import BulkOverlapped from "./BulkOverlapped";
import CheckJWTer from "../hooks/useCheckJWT";

function copyDivToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

function JoinText_keywords(array, seed) {
  let a = [seed];
  for (let i = 0; i < array.length; i++) {
    const text = array[i].keyword;
    const joined = "\n" + text;
    a.push(joined);
  }
  return a.join(" ");
}

function JoinText_keywords_old(array, seed) {
  let a = [seed];
  for (let i = 0; i < array.length; i++) {
    const text = array[i];
    const joined = "\n" + text;
    a.push(joined);
  }
  return a.join(" ");
}

let keywords_clipboard = null;

let project = "";

let keywords_typed = "";

function BulkKeywordCluster(props) {
  const [keywords, setKeywords] = useState("");
  const [keywords_typed, setKeywords_typed] = useState(" ");
  const [projects_kwc, set_projects_kwc] = useState("");
  const [copy_button, set_copy_button] = useState("");
  const [projectS, setProjectS] = useState("");
  const context = useContext(SailorContent);
  const [data] = CheckJWTer();
  const checker_keys = (arr, target) => target.every((v) => arr.includes(v));

  const add_kwc = () => {
    if (keywords.length > 10000) {
      alert("Woa, too many keywords! We process 10k per batch, chill out");
      return;
    }
    let payload = { list_keyword: keywords };
    fetch(context.api_url + "Keywords/BulkAddKWCProject", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("_id")) {
          map_kwc_bulk_projects();
          refresh_kwcs();
        } else {
        }
      })
      .catch(() => { });
  };

  const pull_1_kwc = (id) => {
    fetch(context.api_url + "Keywords/PullSpecificKWCBulk/?project_id=" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        console.log(rx);
        project = rx;
        map_keywords(rx);
      })
      .catch(() => {
        set_projects_kwc(<div>Something messed up, please pull/refresh projects again</div>);
      });
  };

  const overlap_button = () => {
    map_overlaps(project);
  };

  //pass this as prop to bring back the mapped keywords
  const keywords_button = () => {
    map_keywords(project);
  };

  const map_keywords = (rx) => {
    setProjectS(rx);
    const keys = Object.keys(rx);
    if (checker_keys(keys, ["_id", "user_id", "done", "seed", "god_layer", "overlapping"])) {
      if (rx.god_layer[0].keyword !== undefined) {
        let temp = rx.god_layer;
        let all_kws = JoinText_keywords(temp, rx.seed);
        keywords_clipboard = all_kws;

        let overlapping_button = null;

        if (rx.god_layer.length > 0 && "serp_results" in rx.god_layer[0]) {
          overlapping_button = <button onClick={overlap_button}>View Overlaps</button>;
        }

        let copy_button_e = (<button className="kwc-copy-results" onClick={copyResults}>
          Copy Results to Clipboard
        </button>);

        set_projects_kwc(
          <div>
            <div className="kwc-clcked-project">
              <div className="kwc-horizontal">
                <div className="kwc-clicked-seed">Seed: {rx.seed}</div>
                <div className="kwc-clicked-done">Done: {rx.done || rx.done === "true" ? "yes" : "no"}</div>
              </div>
              <div>
                {temp.map((arr) => (
                  <div className={`${arr.serp_results.length === 0 ? "kwc-clicked-kw-incomplete" : "kwc-clicked-kw"}`}>{arr.keyword}</div>
                ))}
              </div>
            </div>
            <div className="kwc-horizontal">
              <div>{overlapping_button}</div>
              <div>{copy_button_e}</div>
            </div>
          </div>
        );
      } else {
        let lt_1 = rx.god_layer.map((arr) => arr);
        let temp = lt_1;
        let all_kws = JoinText_keywords_old(temp, rx.seed);
        keywords_clipboard = all_kws;

        let overlapping_button = null;

        if (rx.god_layer.length > 0 && "serp_results" in rx.god_layer[0]) {
          overlapping_button = <button onClick={overlap_button}>View Overlaps</button>;
        }

        let copy_button_e = (<button className="kwc-copy-results" onClick={copyResults}>
          Copy Results to Clipboard
        </button>);

        set_projects_kwc(
          <div>
            <div className="kwc-clcked-project">
              <div className="kwc-horizontal">
                <div className="kwc-clicked-seed">Seed: {rx.seed}</div>
                <div className="kwc-clicked-done">Done: {rx.done || rx.done === "true" ? "yes" : "no"}</div>
              </div>
              <div>
                {temp.map((arr) => (
                  <div className="kwc-clicked-kw">{arr}</div>
                ))}
              </div>
              <button className="kwc-copy-results" onClick={copyResults}>
                Copy Results to Clipboard
              </button>
            </div>
            <div className="kwc-horizontal">
              <div>{overlapping_button}</div>
              <div>{copy_button_e}</div>
            </div>
          </div>

        );
      }
    } else {
      set_projects_kwc(<div>Something messed up, please pull/refresh projects again</div>);
    }
  };

  const map_overlaps = (rx) => {
    let projz = <BulkOverlapped projectz={rx} keywords_buttonz={keywords_button} />;
    set_projects_kwc(projz);
  };

  const map_kwc_bulk_projects = () => {
    let z = context.kwc_bulk_projects.map((arr) => (
      <BulkKwcProject
        key={arr.id}
        id={arr.id}
        user_id={arr.user_id}
        done={arr.done}
        seed={arr.seed}
        god_layer={arr.god_layer_count}
        selection={click_on_kwc_proj}
      />
    ));
    set_projects_kwc(<div className="kwc-projects">{z}</div>);
    set_copy_button();
  };

  const refresh_kwcs = () => {
    fetch(context.api_url + "Keywords/PullAllKWCBulk", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
      mode: "cors",
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("projs") && rx.projs != null) {
          for (let proj of rx.projs) {
            //only add projects that don't already exist with the same id
            if (context.kwc_bulk_projects.filter((e) => e.id === proj.id).length === 0) {
              context.kwc_bulk_projects.unshift(proj);
            }
            let foundIndex = context.kwc_bulk_projects.findIndex((x) => x.id === proj.id);
            context.kwc_bulk_projects[foundIndex].god_layer_count = proj.god_layer_count;
            context.kwc_bulk_projects[foundIndex].done = proj.done === "true" ? "Yes" : "Processing...";
          }
        }
        map_kwc_bulk_projects();
        // set_projects_kwc(JSON.parse(rx));
      })
      .catch((error) => {
        console.log(error);
        map_kwc_bulk_projects();
      });
  };

  const copyResults = () => {
    try {
      copyDivToClipboard(keywords_clipboard);
    } catch (error) { }
  };

  const click_on_kwc_proj = (thing) => {
    //pull project here with http
    pull_1_kwc(thing);
  };

  useEffect(() => {
    try {
      refresh_kwcs();
    } catch (ex) {
      return;
    }
  }, []);

  useEffect(() => {
    map_kwc_bulk_projects();
  }, [context.kwc_bulk_projects]);

  const submitHandler = (event) => {
    event.preventDefault();
    try {
      add_kwc();
      refresh_kwcs();
    } catch (ex) {
      return;
    }
  };

  const inputChangeHandler = (event) => {
    const regex = /(?:\r\n|\r|\n)/g;
    // let cleaned_kwc = event.target.value.replace(regex, ",");
    let cleaned_kwc = event.target.innerText.replace(regex, ",").split(",");
    setKeywords(cleaned_kwc);
    // setKeywords_typed(event.target.innerText);
  };

  const refreshProjectsHandler = (event) => {
    refresh_kwcs();
  };

  return (
    <div>
      <MainNavigation current="Bulk Discover Keywords" />
      <div className="kwc-wrapper">
        <div className="kwclusters-bulk">
          <div className="kwc-swap-bulk-normal"><Link to="/keyword-clusters">Discover Keywords</Link>  |  <Link to="/bulk-keyword-clusters">[Bulk Discover Keywords]</Link></div>
          <div>
            <div className="compare-credit">
              Tool idea by{"  "}
              <a href="https://www.facebook.com/groups/googleseomastermind" target="_blank" rel="noreferrer">
                Schieler Mew
              </a>{"  |  "}
              <a href="https://kazanseo.com/blog/kazanseo-bulk-keyword-clusters-how-it-works/" target="_blank" rel="noreferrer">
                How it Works
              </a>
            </div>
            <div className="compare-credit">
              Click "View projects" to see the udpated keywords. If red, the keyword is still pending, green is done. This is the same as keyword clustering
              ideas except you can do multiple keywords at the time
            </div>
            <div className="kwc-info">Input Keywords Separated by Comma or New Line</div>
            <div className="bulk-keyworder" contentEditable="true" suppressContentEditableWarning onInput={inputChangeHandler}>
              {keywords_typed}
            </div>
            <div className="kwc-buttons">
              <button onClick={refreshProjectsHandler}>View Projects</button>
              <button onClick={submitHandler}>Cluster Keywords</button>

            </div>
          </div>
          <div className="kwc-bar-label">Projects</div>
          <div className="kwc-bar-separator"></div>
        </div>
        <div>{projects_kwc}</div>
      </div>
    </div>
  );
}

export default BulkKeywordCluster;
