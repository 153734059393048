import React, { useState, useEffect, useContext } from "react";
import "./css/Refill.css";
import { withRouter, useHistory } from "react-router-dom";
import CardFormPaypalSub from "./CardPaypalSub";
// import {Elements} from "@stripe/react-stripe-js";
// import {loadStripe} from "@stripe/stripe-js";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
// import kazano from "../media/kazano.png";
import logo from "../media/logo.svg";
import brainkazano from "../media/brainkazano.png";

function RefillSub(props) {
  const [balance, setBalance] = useState("pulling active sub...");
  const [email, setEmail] = useState("");
  const context = useContext(SailorContent);
  const [emailL, setEmailL] = useState("");
  const [password, setPassword] = useState("");
  const [btn_msg, set_btn_msg] = useState("Create Account!");
  const [btn_class, set_btn_class] = useState("");

  const [show_paypal, set_show_paypal] = useState("");

  const history = useHistory();

  const json_keys = (arr, target) => target.every((v) => arr.includes(v));

  const emailChangeHandler = (event) => {
    setEmailL(event.target.value);
    console.log(emailL);
  };

  const passChangeHandler = (event) => {
    setPassword(event.target.value);
    console.log(password);
  };

  const SignUp = () => {
    let payload = { email: emailL, pass: password };
    fetch(context.api_url + "SailorMonolithic/SignUp", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        const keys = Object.keys(rx);
        if (rx.email.includes("Already in Use")) {
          setBalance(<div className="ext-link-message">Email Already In Use</div>);
          set_btn_msg("Try Again!");
        } else if (json_keys(keys, ["oblastjwttoken", "user_ID"])) {
          context.jwt = rx.oblastjwttoken;
          context.user_id = rx.user_ID;
          //store the jwt
          localStorage.setItem("potentially_sailor_JWT", rx.oblastjwttoken);
          localStorage.setItem("potentially_sailor_UID", rx.user_ID);
          console.log("success");
          context.isLoggedIn = true;
          set_btn_class("landing-button-hidden");
          setBalance(<div className="sign-up-form-title">Thank you! Click the link above for the extension and subscribe below</div>);
          set_show_paypal(<CardFormPaypalSub />);
          // set_show_paypal("thank you for being awesome, get the extension above");
        } else {
          setBalance(<div className="ext-link-message">Weird Error, Try Again</div>);
        }
      })
      .catch(() => {
        console.log("Signing up failed");
      });
  };

  const SignUpHandler = () => {
    if (/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(emailL) === false) {
      console.log("invalid email");
      console.log(emailL);
      setBalance(<div className="ext-link-message">Invalid Email, Try again</div>);
      return;
    }
    if (emailL.length < 5 || password.length < 5) {
      setBalance(<div className="ext-link-message">Email and pass has to be longer than 5 characters</div>);
    } else {
      try {
        set_btn_msg("Creating...");
        setBalance("");
        SignUp();
      } catch (ex) {
        set_btn_msg("Try Again!");
      }
    }
  };

  const bal_response = () => {
    let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
    fetch(context.api_url + "GenerateText/pull-sub", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${tokener.token}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (!rx.hasOwnProperty("subq") || !rx.hasOwnProperty("last_payment")) {
          setBalance("error pulling the sub, sorry :( try again later");
          set_show_paypal(<CardFormPaypalSub />);
          // set_show_paypal("thank you for being awesome, get the extension above");
          return;
        }
        if (rx.subq === "true") {
          setBalance(`Last Subbed ${rx.last_payment} You're good to go! thank you!`);
          set_show_paypal("thank you for being awesome, get the extension above");
        } else if (rx.subq === "never susbcribed") {
          setBalance(
            <div>
              <div>You're not subscribed yet</div>
              <div className="sign-up-form-title">Subscribe Below For Insane Detection Productivity</div>
            </div>
          );
          set_show_paypal(<CardFormPaypalSub />);
          // set_show_paypal("thank you for being awesome, get the extension above");
        } else if (rx.last_payment === "expired subscription") {
          setBalance("Expired Subscription, please renew to access the chrome extension, the premium detector and the bulk detector");
          set_show_paypal(<CardFormPaypalSub />);
          // set_show_paypal("thank you for being awesome, get the extension above");
        } else {
          setBalance("error pulling the sub, sorry :( try again later");
          set_show_paypal(<CardFormPaypalSub />);
        }
      })
      .catch(() => {
        setBalance("Sign Up Now for the full experience");
        // set_show_paypal(<CardFormPaypalSub />);
      });
  };

  const get_email_user = () => {
    let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
    fetch(context.api_url + "SailorMonolithic/GetEmail", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${tokener.token}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("em")) {
          setEmail(rx.em);
        } else {
          setEmail("");
        }
      })
      .catch(() => {
        setEmail("");
      });
  };

  useEffect(() => {
    try {
      bal_response();
      get_email_user();
    } catch (ex) {
      setBalance("pulling sub failed");
      return;
    }
  }, []);

  return (
    <div>
      <MainNavigation current="Subscribe" />
      <div className="refill">
        <div className="slidecontainer">
          <div className="refill-square">
            <h1>Welcome to AI Intuition</h1>
            <h3>Hey! {email}</h3>
            <div className="center-kazano">
              <img src={logo} alt="kazano" className="login-kazano-r" />
            </div>
            <h3>Experience the Internet Authentically. Discover Human-Crafted Content.</h3>
            <p>
              Introducing AI Intuition by KazanSEO, the AI content detection tool that revolutionizes your digital journey. Seamlessly integrated as a Google
              Chrome extension, AI Intuition enriches your browsing experience by sifting through the cyber wilderness to highlight genuine human-crafted
              content. This leading-edge technology redefines how you interact with the digital world, ensuring authenticity at each click, all for a mere $9
              per month.
            </p>
            <p>
              <strong>✔ For Teachers:</strong> AI Intuition assists you in cultivating a genuine and enriching educational environment. Handpick only
              human-generated content, ensuring the highest level of engagement and originality for your students' learning experience.
            </p>
            <p>
              <strong>✔ For Writers:</strong> Unearth the power of human creativity with AI Intuition. Instead of AI-crafted articles, gain inspiration from
              true human experiences and original narratives, enhancing your creativity and enriching your writing process.
            </p>
            <p>
              <strong>✔ For Artists:</strong> Find inspiration in the authentic human emotion encapsulated in human-created content. AI Intuition opens the door
              to a world of creativity unimpeded by the artificiality of AI, helping you connect more profoundly with your art.
            </p>
            <p>
              <strong>✔ For Entrepreneurs and Bosses:</strong> Navigate the digital marketplace with a focus on human innovation and thought leadership. AI
              Intuition helps you identify genuine trends, ideas, and competitor strategies, empowering you to make strategic and informed decisions.
            </p>
            <p>
              <strong>✔ For Agencies:</strong> Deliver human-centric strategies with AI Intuition. Rely on human creativity and innovation to build your
              campaigns, ensuring your work resonates deeply with your audience's human experience.
            </p>
            <p>
              <strong>✔ For Everyone Who Values Authenticity:</strong> If you want to experience the internet as a platform for real human interaction and
              expression, AI Intuition is your trusted ally. By quickly flagging AI-generated content, our tool ensures that your internet journey is genuinely
              human.
            </p>
            <p>
              AI Intuition doesn't just enhance your productivity; it transforms your online experience, bringing you closer to the true essence of human
              creativity and intellect. Say goodbye to the impersonal AI-crafted content and say hello to a more authentic digital world.
            </p>
            <p>Take a step towards authenticity. Discover the power of human creativity with AI Intuition.</p>
            <p>Welcome to a more genuine internet, welcome to AI Intuition by KazanSEO.</p>
            <h2 className="ext-link-h2">
              <a
                className="ext-link"
                href="https://chrome.google.com/webstore/detail/kazanseo-ai-content-detec/lfijlkidolonkfhjehohinofjdgfneah"
                rel="noreferrer"
                target="_blank">
                Get the Extension Here
              </a>
            </h2>
            <h2>For Only $9 per month you'll get the AI Detector Extension</h2>
          </div>
        </div>
        <div className="signupper">
          <h3>Do you have the extension already?</h3>
          <h4>{balance}</h4>
          {show_paypal === "" ? (
            <div className="landing-form-fields">
              <div className="sign-up-form-title">Create an account to access the extension:</div>
              <label>Email</label>
              <input type="email" value={emailL} onChange={emailChangeHandler} />
              <label>Password</label>
              <input type="text" value={password} onChange={passChangeHandler} />
              <div className={btn_class}>
                <button type="button" onClick={SignUpHandler}>
                  {btn_msg}
                </button>
              </div>
            </div>
          ) : (
            show_paypal
          )}
        </div>
      </div>
    </div>
  );
}

export default RefillSub;
