import "./css/KeywordCluster.css";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Extensions from "../Extensions";
import KwcProject from "./KwcProject";
import Overlapped from "./Overlapped";
import CheckJWTer from "../hooks/useCheckJWT";

function copyDivToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

function JoinText_keywords(array, seed) {
  let a = [seed];
  for (let i = 0; i < array.length; i++) {
    const text = array[i].keyword;
    const joined = "\n" + text;
    a.push(joined);
  }
  return a.join(" ");
}

function JoinText_keywords_old(array, seed) {
  let a = [seed];
  for (let i = 0; i < array.length; i++) {
    const text = array[i];
    const joined = "\n" + text;
    a.push(joined);
  }
  return a.join(" ");
}

let keywords_clipboard = null;

let project = "";

function KeywordCluster(props) {
  const [keyword, setKeyword] = useState("");
  const [projects_kwc, set_projects_kwc] = useState("");
  const [copy_button, set_copy_button] = useState("");
  const [projectS, setProjectS] = useState("");
  const context = useContext(SailorContent);
  const [data] = CheckJWTer();

  const checker_keys = (arr, target) => target.every((v) => arr.includes(v));

  const add_kwc = () => {
    let payload = { seed: keyword };
    fetch(context.api_url + "Keywords/AddKWCProject", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("_id")) {
          map_kwc_projects();
          refresh_kwcs();
        } else {
        }
      })
      .catch(() => { });
  };

  const pull_1_kwc = (id) => {
    fetch(context.api_url + "Keywords/PullSpecificKWC/?project_id=" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        console.log(rx);
        project = rx;
        map_keywords(rx);
      })
      .catch(() => {
        set_projects_kwc(<div>Something messed up, please pull/refresh projects again</div>);
      });
  };

  const overlap_button = () => {
    map_overlaps(project);
  };

  //pass this as prop to bring back the mapped keywords
  const keywords_button = () => {
    map_keywords(project);
  };

  const map_keywords = (rx) => {
    setProjectS(rx);
    const keys = Object.keys(rx);
    if (checker_keys(keys, ["_id", "user_id", "done", "seed", "layer_1", "layer_2", "layer_3", "overlapping"])) {
      if (rx.layer_1[0].keyword !== undefined) {
        let temp = rx.layer_1;
        temp.push.apply(temp, rx.layer_2);
        temp.push.apply(temp, rx.layer_3);
        let all_kws = JoinText_keywords(temp, rx.seed);
        keywords_clipboard = all_kws;

        let overlapping_button = null;

        if (rx.layer_3.length > 0 && "serp_results" in rx.layer_3[0]) {
          overlapping_button = <button onClick={overlap_button}>View Overlaps</button>;
        }

        let copy_button_e = (<button className="kwc-copy-results" onClick={copyResults}>
          Copy Results to Clipboard
        </button>);

        set_projects_kwc(
          <div>
            <div className="kwc-clcked-project">
              <div className="kwc-horizontal">
                <div className="kwc-clicked-seed">Seed: {rx.seed}</div>
                <div className="kwc-clicked-done">Done: {rx.done || rx.done === "true" ? "yes" : "no"}</div>
              </div>
              <div>
                {temp.map((arr) => (
                  <div className="kwc-clicked-kw">{arr.keyword}</div>
                ))}
              </div>
            </div>
            <div className="kwc-horizontal">
              <div>{overlapping_button}</div>
              <div>{copy_button_e}</div>
            </div>
          </div>
        );
      } else {
        let lt_1 = rx.layer_1.map((arr) => arr);
        let lt_2 = rx.layer_2.map((arr) => arr);
        let lt_3 = rx.layer_3.map((arr) => arr);
        let temp = lt_1;
        temp.push.apply(temp, lt_2);
        temp.push.apply(temp, lt_3);
        let all_kws = JoinText_keywords_old(temp, rx.seed);
        keywords_clipboard = all_kws;

        let overlapping_button = null;

        if (rx.layer_3.length > 0 && "serp_results" in rx.layer_3[0]) {
          overlapping_button = <button onClick={overlap_button}>View Overlaps</button>;
        }

        let copy_button_e = (<button className="kwc-copy-results" onClick={copyResults}>
          Copy Results to Clipboard
        </button>);

        set_projects_kwc(
          <div>
            <div className="kwc-clcked-project">
              <div className="kwc-horizontal">
                <div className="kwc-clicked-seed">Seed: {rx.seed}</div>
                <div className="kwc-clicked-done">Done: {rx.done || rx.done === "true" ? "yes" : "no"}</div>
              </div>
              <div>
                {temp.map((arr) => (
                  <div className="kwc-clicked-kw">{arr}</div>
                ))}
              </div>
            </div>
            <div className="kwc-horizontal">
              <div>{overlapping_button}</div>
              <div>{copy_button_e}</div>
            </div>
          </div>
        );
      }
    } else {
      set_projects_kwc(<div>Something messed up, please pull/refresh projects again</div>);
    }
  };

  const map_overlaps = (rx) => {
    let projz = <Overlapped projectz={rx} keywords_buttonz={keywords_button} />;
    set_projects_kwc(projz);
  };

  const map_kwc_projects = () => {
    let z = context.kwc_projects.map((arr) => (
      <KwcProject
        key={arr.id}
        id={arr.id}
        user_id={arr.user_id}
        done={arr.done}
        seed={arr.seed}
        layer_1={arr.layer_1_count}
        layer_2={arr.layer_2_count}
        layer_3={arr.layer_3_count}
        selection={click_on_kwc_proj}
      />
    ));
    set_projects_kwc(<div className="kwc-projects">{z}</div>);
  };

  const refresh_kwcs = () => {
    fetch(context.api_url + "Keywords/PullAllKWC", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
      mode: "cors",
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("projs") && rx.projs != null) {
          for (let proj of rx.projs) {
            //only add projects that don't already exist with the same id
            if (context.kwc_projects.filter((e) => e.id === proj.id).length === 0) {
              context.kwc_projects.unshift(proj);
            }
            let foundIndex = context.kwc_projects.findIndex((x) => x.id === proj.id);
            context.kwc_projects[foundIndex].layer_1_count = proj.layer_1_count;
            context.kwc_projects[foundIndex].layer_2_count = proj.layer_2_count;
            context.kwc_projects[foundIndex].layer_3_count = proj.layer_3_count;
            context.kwc_projects[foundIndex].done = proj.done === "true" ? "Yes" : "Processing...";
          }
        }
        map_kwc_projects();
      })
      .catch((error) => {
        console.log(error);
        map_kwc_projects();
      });
  };

  const copyResults = () => {
    try {
      copyDivToClipboard(keywords_clipboard);
    } catch (error) { }
  };

  const click_on_kwc_proj = (thing) => {
    //pull project here with http
    pull_1_kwc(thing);
  };

  useEffect(() => {
    try {
      refresh_kwcs();
    } catch (ex) {
      return;
    }
  }, []);

  useEffect(() => {
    map_kwc_projects();
  }, [context.kwc_projects]);

  const submitHandler = (event) => {
    event.preventDefault();
    try {
      add_kwc();
      refresh_kwcs();
    } catch (ex) {
      return;
    }
  };

  const inputChangeHandler = (event) => {
    let cleaned_kwc = event.target.value.replace("*", "");
    setKeyword(cleaned_kwc);
  };

  const refreshProjectsHandler = (event) => {
    refresh_kwcs();
  };

  return (
    <div>
      <MainNavigation current="Discover Keywords" />
      <div className="kwc-wrapper">
        <div className="kwclusters">
          <div className="kwc-swap-bulk-normal"><Link to="/keyword-clusters">[Discover Keywords]</Link>  |  <Link to="/bulk-keyword-clusters">Bulk Discover Keywords</Link></div>
          <div>
            <div className="compare-credit">
              Tool idea by{"  "}
              <a href="https://www.facebook.com/groups/googleseomastermind" target="_blank" rel="noreferrer">
                Schieler Mew
              </a>  |  This is {"  "}
              <a href="https://kazanseo.com/blog/kazanseo-keyword-clusters-how-it-works/" target="_blank" rel="noreferrer">
                How it works
              </a>
            </div>
            <div className="compare-credit">

            </div>
            <div className="compare-credit">
              Wait ~30 secs after [cluster keywords] for results, [pull/refresh projects] for refreshing. For local
              keywords do this: tucson plumbing.
            </div>
            <div className="compare-credit">View the overlap from the google results between all the keywords discovered</div>
            <div className="kwc-info">Input your seed keyword to get more keyword ideas</div>
            <input type="text" value={keyword} onChange={inputChangeHandler} />
            <div className="kwc-buttons">
              <button onClick={submitHandler}>Cluster Keywords</button>
              <button onClick={refreshProjectsHandler}>Pull/Refresh Projects</button>
            </div>
          </div>
          <div className="kwc-bar-label">Projects</div>
          <div className="kwc-bar-separator"></div>
        </div>
        <div>{projects_kwc}</div>
      </div>
    </div>
  );
}

export default KeywordCluster;
