import React from 'react';
import './css/ProjectCreation.css'


function ProjectCreation(props) {

    return (
        <div className="project-creation">
            <div className="project-creation-text">Project Being Created... (usually takes 40 seconds, don't close the tab!)</div>
            <div className="lds-hourglass"></div>
            <button type="button" onClick={props.onCancel}>Cancel</button>
        </div>
    );

}

export default ProjectCreation;