import React, { useState, useEffect, useContext } from "react";
import "./css/CompareKeywords.css";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import ResultsKeywords from "./ResultsUrls";
import CheckJWTer from "../hooks/useCheckJWT";

let controller = null;
let signal = null;

function CompareKws(keywordx, context) {
  controller = new AbortController();
  signal = controller.signal;
  let jsonObject = { keywords: keywordx };
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + "Compare/CompareKeywords", {
        method: "post",
        signal: signal,
        body: JSON.stringify(jsonObject),
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "localhost",
          Authorization: `Bearer  ${context.jwt}`,
        },
        mode: "cors",
      });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject("404 error");
      }
    } catch (ex) {
      if (isAbortError(ex)) {
        console.log(ex.message);
        reject("aborted");
      } else {
        console.log("random other error");
        console.log(ex);
        reject("random other error");
      }
    }
  });
  promise.cancel = () => controller.abort();
  return promise;
}

function isAbortError(error) {
  if (error && error.name === "AbortError") {
    return true;
  }
  return false;
}

function CompareKeywords() {
  const [ratio, setRatio] = useState(null);
  const [keywords, setKeywords] = useState("");
  const [resultKeywords, setResultKeywords] = useState(null);
  const [result_urls, setResult_urls] = useState(null);
  const [results_component, setResults_component] = useState(null);
  const [counter, setCounter] = useState(null);
  const [repeated_label, setrepeated_label] = useState(null);
  const [query, setQuery] = useState(null);
  const context = useContext(SailorContent);
  const [data] = CheckJWTer();

  const keywordsChangeHandler = (event) => {
    setKeywords(event.target.value);
  };

  const cancelRequest = (event) => {
    try {
      controller.abort();
      query.cancel();
    } catch (error) { }
  };

  const submitHandler = (event) => {
    //this prevents the page from being reloaded after submitting the form
    event.preventDefault();
    const nameArr = keywords.split(",");
    for (let i = 0; i < nameArr.length; i++) {
      nameArr[i] = nameArr[i].trim();
    }
    setResultKeywords(nameArr);
    const q = CompareKws(nameArr, context);
    setQuery(q);
    q.then((results) => {
      console.log("success");
      console.log(results);

      //parse the json result
      setRatio(results.total_urls_repeated);
      setResult_urls(results.repeated_urls);
    }).catch((whattodo) => {
      console.log("catch other error");
      //write "oops, something went wrong, try again"
    });
  };

  let superArray = [];
  if (resultKeywords != null) {
    superArray = resultKeywords.map((arr) => <ResultsKeywords key={arr.url} keyword={arr.url} />);
  }

  useEffect(() => {
    if (result_urls != null) {
      let z = result_urls.map((arr) => <ResultsKeywords key={arr} url={arr} />);
      setResults_component(z);
    }
  }, [result_urls]);

  useEffect(() => {
    if (ratio != null) {
      let x = (
        <div className="compare-word-counter">
          <div className="compare-words">Repeated Urls:</div>
          <div className="compare-current-words">{ratio}</div>
        </div>
      );
      setCounter(x);
    }
  }, [ratio]);

  useEffect(() => {
    if (ratio != null) {
      let y = <label className="compare-repeated-urls">The following urls were repeated:</label>;
      setrepeated_label(y);
    }
  }, [ratio]);

  return (
    <div>
      <MainNavigation current="Compare Keywords" />
      <div className="compare-wrapper">
        <div className="compare-credit compare-instructions">
          Tool idea by{"  "}
          <a href="https://www.facebook.com/groups/googleseomastermind" target="_blank" rel="noreferrer">
            Schieler Mew
          </a>{"  |  "}
          <a href="https://kazanseo.com/blog/kazanseo-serp-overlap-how-it-works/" target="_blank" rel="noreferrer">
            How to Use Guide
          </a>
        </div>
        <div className="compare-instructions">Input any 2, 3, 4... etc keywords and see if their results from google overlap.</div>
        <div className="compare-instructions">This will tell you if those keywords have a shared meaning in google's eyes.</div>
        <form className="compare-form-form" onSubmit={submitHandler}>
          <div className="compare-form-top">
            <div className="compare-form-title">
              <label>Type Keywords Separated by Comma to Compare Results</label>
              <input type="text" value={keywords} onChange={keywordsChangeHandler} placeholder="Please type your keywords here..." />
            </div>
          </div>
          <div className="compare-form-actions">
            <button type="submit">Compare Keywords</button>
            <button type="button" onClick={cancelRequest}>
              Cancel
            </button>
          </div>
        </form>
        <label className="compare-results">Results Here:</label>
        <div className="kwc-bar-separator"></div>
        <div>{counter}</div>
        <div>{repeated_label}</div>
        <div>{results_component}</div>
      </div>
    </div>
  );
}

export default CompareKeywords;
