import React, { useState, useEffect, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import SailorContent from "../store/SailorContext";
import "./css/ForgotPass.css";
import logo from "../media/logo.svg";

const ForgotPass = (props) => {
  const context = useContext(SailorContent);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("email");
  const [new_pass, set_new_pass] = useState("new password");

  const currentLocation = window.location.href.split("?token=");
  let token = "";

  let contentx = "";

  const forgot_pass_button = () => {
    let payload = {
      email: email,
    };

    fetch(context.api_url + `SailorMonolithic/CheckEmailForgotPass/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
      body: JSON.stringify(payload),
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("msj")) {
          if (rx.msj === "Please check your junk folder") {
            setErrorMessage("Email Sent, Please check your junk folder");
          }
        } else {
          setErrorMessage("no message back from the server, you set it on fire, good job!");
        }
      })
      .catch(() => {
        setErrorMessage("server error, you set it on fire, good job!, or your account doesn't even exist");
      });
  };

  const update_pass_button = () => {
    let payload = {
      email: email,
      pass: new_pass,
    };

    fetch(context.api_url + `SailorMonolithic/UpdatePassword/?token=${token}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
      body: JSON.stringify(payload),
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("oblastjwttoken") && rx.hasOwnProperty("user_ID")) {
          context.jwt = rx.oblastjwttoken;
          context.user_id = rx.user_ID;
          //store the jwt
          localStorage.setItem("potentially_sailor_JWT", rx.oblastjwttoken);
          localStorage.setItem("potentially_sailor_UID", rx.user_ID);
          context.isLoggedIn = true;
          history.push({
            pathname: "/",
          });
        } else {
          setErrorMessage("Couldn't update the password, something happened");
        }
      })
      .catch(() => {
        setErrorMessage("server error, you set it on fire, good job!, or your account doesn't even exist");
      });
  };
  const email_change_handler = (event) => {
    setEmail(event.target.value);
  };
  const pass_change_handler = (event) => {
    set_new_pass(event.target.value);
  };

  //set the token thing
  if (currentLocation.length > 1) {
    token = currentLocation[1];
    contentx = (
      <div className="forgot-main-fields">
        <label>New Password</label>
        <input type="text" value={new_pass} onChange={pass_change_handler} />
        <button type="button" onClick={update_pass_button}>
          Update Password
        </button>
      </div>
    );
  } else {
    contentx = (
      <div className="forgot-main-fields">
        <label>Email</label>
        <input type="email" value={email} onChange={email_change_handler} />
        <button type="button" onClick={forgot_pass_button}>
          Send Restore Email
        </button>
      </div>
    );
  }

  return (
    <div className="forgot-main">
      <img src={logo} className="App-logo forgot-logo" alt="logo" />
      <div className="forgot-main-top"></div>
      <div>{contentx}</div>
      <div className="forgot-main-warning">An email will be sent to you, click it and update your password</div>
      <div className="forgot-main-warning">If you already clicked it then just update your password</div>
      <div className="forgot-main-warning-blue">{errorMessage}</div>
      <div>
        <a className="forgot-back-to-login" href="/">
          Back to Login / Sign Up
        </a>
      </div>
    </div>
  );
};

export default ForgotPass;
