import React, { useState, useEffect, useContext } from "react";
import "./css/ThankYou.css";
import { withRouter, useHistory, Link } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";

function ThankYou(props) {
  const [balance, setBalance] = useState("pulling active sub...");
  const [email, setEmail] = useState("");
  const context = useContext(SailorContent);

  const history = useHistory();

  const bal_response = () =>
    fetch(context.api_url + "GenerateText/pull-sub", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (!rx.hasOwnProperty("subq") || !rx.hasOwnProperty("last_payment")) {
          setBalance("error pulling the sub, sorry :( try again later");
          return;
        }
        if (rx.subq === "true") {
          setBalance(`Last Subbed ${rx.last_payment} You're good to go! thank you!`);
        } else if (rx.subq === "never susbcribed") {
          setBalance(
            "You're not subscribed! You won't get access to our Chrome Extension and logs but you can use the standard detector to your left just fine"
          );
        } else if (rx.last_payment === "expired subscription") {
          setBalance("Expired Subscription, please renew to access the chrome extension, the premium detector and the bulk detector");
        } else {
          setBalance("error pulling the sub, sorry :( try again later");
        }
      })
      .catch(() => {
        setBalance("error pulling sub, sorry :( you might not be subbed :O");
      });

  const get_email_user = () =>
    fetch(context.api_url + "SailorMonolithic/GetEmail", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("em")) {
          setEmail(rx.em);
        } else {
          setEmail("");
        }
      })
      .catch(() => {
        setEmail("");
      });

  useEffect(() => {
    try {
      bal_response();
      get_email_user();
    } catch (ex) {
      setBalance("pulling sub failed");
      return;
    }
  }, []);

  return (
    <div>
      <MainNavigation current="Subscribe" />
      <div className="refill">
        <div className="slidecontainer">
          <div className="refill-square">
            <h1>THANK YOU for subscribing to AIntuition</h1>
            <h3>Hey! {email}</h3>
            <div>Please click below to get your brand new extension</div>
            <h2 className="ext-link-h2">
              <a
                className="ext-link"
                href="https://chrome.google.com/webstore/detail/kazanseo-ai-content-detec/lfijlkidolonkfhjehohinofjdgfneah"
                rel="noreferrer"
                target="_blank">
                Get the Extension Here
              </a>
            </h2>
          </div>
          <div>
            Go back to the detector <Link to="/detector">Detector</Link>
          </div>
        </div>
        <h3>You are Subscribed from: {balance}</h3>
      </div>
    </div>
  );
}

export default ThankYou;
