import "./css/EmailVerifier.css";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import Extensions from "../Extensions";
import CheckJWTer from "../hooks/useCheckJWT";
import downloadicon from "../media/downloadicon.svg";


function EmailVerifierProj(props) {
    const context = useContext(SailorContent);


    const save_file = (contents) => {
        let csvContent = "data:text/csv;charset=utf-8,";
        if (contents.hasOwnProperty("good_emails")) {
            for (let i = 0; i < contents.good_emails.length; i++) {
                csvContent += contents.good_emails[i] + "\n";
            }
        }
        // console.log(csvContent);
        var encodedUri = encodeURI(csvContent);
        // window.open(encodedUri);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data.csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    const exportButton = () => {
        if (props._id !== "id1") {
            fetch(context.api_url + "Emails/check-verified-emails?proj_id=" + props._id, {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer  ${context.jwt}`,
                    Origin: "",
                    Host: "localhost",
                },
            })
                .then((r) => {
                    return r.json();
                })
                .then((rx) => {
                    save_file(rx);
                })
                .catch(() => {
                    props.setErrMsg(<div>Something messed up, please pull/refresh projects again</div>);
                });
        }
        else {
            props.setErrMsg(<div>Can't export the default project</div>);
        }
    };

    return (
        <div className="ev-proj">
            <div className="ev-proj-seed">{props._id.substring(0, 8)}</div>
            <div className="ev-proj-seed-number">Valid Emails So Far:</div>
            <div className="ev-proj-ev-extracted">{props.good_emails[0]}</div>
            <div className="ev-proj-ev-done-bool">Status:</div>
            <div className="ev-proj-ev-done">{props.finished ? "Finished!" : "Processing..."}</div>
            <div className="pointer-link ev-export" onClick={exportButton}><img src={downloadicon} alt="download icon" /> Download Valid Emails</div>
        </div>
    );
}

export default EmailVerifierProj;
