import "./css/KwcProject.css";

export default function KeywordSharingProject(props) {
  const detector_project_click_handler = () => {
    props.selection(props.id);
  };

  let counter = props.total;

  return (
    <div className="kwc-proj" onClick={detector_project_click_handler}>
      <div className="kwc-proj-seed">Seed:</div>
      <div className="kwc-proj-seed-number">{props.seed}</div>
      <div className="kwc-proj-kwc-extracted">Total Keywords:</div>
      <div className="kwc-proj-kwc-extracted-counter">{counter}</div>
    </div>
  );
}
