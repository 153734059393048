import React, { useState, useEffect, useContext } from "react";
import "./css/Overlapped.css";
import SailorContent from "../store/SailorContext";

function BulkOverlapped(props) {
  const [overlapped_kwc, set_overlapped_kwc] = useState("");
  const [overlapped_serps, set_overlapped_serps] = useState(7);
  const context = useContext(SailorContent);
  const checker_keys = (arr, target) => target.every((v) => arr.includes(v));

  const pull_overlap_request = () => {
    console.log(props);
    fetch(context.api_url + "Keywords/BulkManualOverlap/?project_id=" + props.projectz._id + "&overlap_rate=" + overlapped_serps, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        console.log(rx);
        map_overlaps(rx);
      })
      .catch(() => {
        set_overlapped_kwc(<div>Something messed up, please pull/refresh projects again</div>);
      });
  };

  const map_overlaps = (rx) => {
    const keys = Object.keys(rx);
    if (checker_keys(keys, ["_id", "user_id", "done", "seed", "god_layer", "overlapping"])) {
      let overlaps = null;
      if (rx.god_layer[0].serp_results !== null || rx.god_layer[0].serp_results !== undefined) {
        overlaps = rx.overlapping.map((arr) => (
          <div className="kwc-overlaps">
            {arr.map((clu) => (
              <div>{clu}</div>
            ))}
          </div>
        ));
      } else {
        overlaps = <div className="kwc-overlaps">No Overlaps for this project yet... try again in a few minutes</div>;
      }

      let projz = (
        <div className="kwc-clcked-project">
          <div className="kwc-clicked-seed">
            Grouped keywords that are overlapped by more than {overlapped_serps} serp results: {rx.overlapping !== null ? rx.overlapping.length : "0"}
          </div>
          <div>{overlaps}</div>
        </div>
      );
      set_overlapped_kwc(projz);
    } else {
      set_overlapped_kwc(<div>Something messed up, please pull/refresh projects again</div>);
    }
  };

  const keywords_button = () => {
    props.keywords_buttonz();
  };

  const overlapped_slider_handler = (event) => {
    set_overlapped_serps(event.target.valueAsNumber);
  };

  const pullOverlap = () => {
    try {
      pull_overlap_request();
    } catch (ex) {
      return;
    }
  };

  return (
    <div className="OverlappedMain">
      <button onClick={keywords_button}>View Keywords</button>
      <div className="kwc-clicked-seed">Seed: {props.projectz.seed}</div>
      <div>
        <div className="overlap-rate-slider">
          Set Serp Overlap Rate: <span>{overlapped_serps}</span>
        </div>
        <input type="range" min="2" max="10" step="1" className="slider-ai" onChange={overlapped_slider_handler} value={overlapped_serps} />
      </div>
      <button onClick={pullOverlap}>Pull Overlaps</button>
      <div className="kwc-bar-separator"></div>
      <div>{overlapped_kwc}</div>
    </div>
  );
}

export default BulkOverlapped;
