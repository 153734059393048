import React, { useState, useEffect, useContext } from "react";
import SailorContent from "../store/SailorContext";
import eventTrack from "./eventTrack";
import logo from "../media/logo.svg";
import CardPaypalLanding from "./CardPaypalLanding";
import "./css/Landing.css";

let i = 0;
let txt = `STEP 1: Type in your email and a newly created password\n
STEP 2: Hit "Create Account" (this will create your new account)\n
STEP 3: Finish the email account verification process to get access to INFINITE WORDS of content in minutes\n
STEP ??: It's totally FREE FOREVER and UNLIMITED!\n
STEP 4: Start getting creative and guiding the AI to the ultimate content experience\n
STEP 5: Publish nearly infinite articles to your website\n
STEP 6: Sit back, relax, and watch your website's authority and trust with google grow exponentially\n
STEP 7: Work on conversion optimization to capitalize on all that new traffic you're getting\n
STEP 8: Get a good link building strategy going, join our facebook group an we'll recommend a few high quality vendors\n
STEP 9: Build an email list to convert your traffic even further\n
STEP 10: Retarget those people on facebook, it will be easy, cheap and highly converting\n
STEP X: Rinse and repeat with more and more websites\n
********************************** ********************************** **********************************\n
This tool is meant to be used in a desktop environment, please use the same login credentials when visiting the desktop versio\n
Will we email you in the future to tell you about new things? Probably yes`; /* The text */
let speed = 2; /* The speed/duration of the effect in milliseconds */
let docL = 1;

const typeWriter = () => {
  if (i < txt.length) {
    document.getElementById("animate-here").innerHTML += txt.charAt(i);
    docL.scrollTop = docL.scrollHeight;
    i++;
    setTimeout(typeWriter, speed);
  }
};

function Landing(props) {
  const [emailL, setEmailL] = useState("");
  const [animation_content, set_animation_content] = useState("");
  const [password, setPassword] = useState("");
  const [btn_msg, set_btn_msg] = useState("Create Account!");
  const [btn_class, set_btn_class] = useState("");
  const [show_paypal, set_show_paypal] = useState(<h1> </h1>);
  const context = useContext(SailorContent);
  const [acc_created, set_acc_created] = useState("landing-acc-created-hidden");

  const json_keys = (arr, target) => target.every((v) => arr.includes(v));

  const SignUp = () => {
    let payload = { email: emailL, pass: password };
    fetch(context.api_url + "SailorMonolithic/SignUp", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        const keys = Object.keys(rx);
        if (rx.email.includes("Already in Use")) {
          set_show_paypal(<div>Email Already In Use</div>);
          set_btn_msg("Try Again!");
        } else if (json_keys(keys, ["oblastjwttoken", "user_ID"])) {
          context.jwt = rx.oblastjwttoken;
          context.user_id = rx.user_ID;
          //store the jwt
          localStorage.setItem("potentially_sailor_JWT", rx.oblastjwttoken);
          localStorage.setItem("potentially_sailor_UID", rx.user_ID);
          console.log("success");
          context.isLoggedIn = true;
          set_btn_class("landing-button-hidden");
          // set_show_paypal(<CardPaypalLanding amount={197} />);
          set_show_paypal(
            <div>
              <div>Check your email to unlock the true FREE AI content</div>
              <div className="gen-link">
                <a href="https://kazanseo.com/" target="_blank" rel="noreferrer">
                  Start Your Journey
                </a>
              </div>
            </div>
          );
          set_acc_created("");
        } else {
          set_show_paypal(<div>Weird Error, Try Again</div>);
        }
      })
      .catch(() => {
        console.log("Signing up failed");
      });
  };

  const SignUpHandler = () => {
    if (/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(emailL) === false) {
      console.log("invalid email");
      console.log(emailL);
      set_show_paypal(<div>Invalid Email, Try again</div>);
      return;
    }
    if (emailL.length < 5 || password.length < 5) {
      set_show_paypal(<div>Email and pass has to be longer than 5 characters</div>);
    } else {
      try {
        set_btn_msg("Creating...");
        SignUp();
        // set_btn_class("landing-button-hidden");
        // set_show_paypal(<CardPaypalLanding amount={197} />);
      } catch (ex) {
        set_btn_msg("Try Again!");
      }
    }
  };

  const emailChangeHandler = (event) => {
    setEmailL(event.target.value);
    console.log(emailL);
  };

  const passChangeHandler = (event) => {
    setPassword(event.target.value);
    console.log(password);
  };

  useEffect(() => {
    set_btn_class("landing-form-buttons");
    docL = document.getElementById("animate-here");
    typeWriter();
  }, []);

  return (
    <div className="main-landing">
      <div className="landing-animation" id="animate-here" suppressContentEditableWarning>
        {animation_content}
      </div>
      <div className="landing-signup">
        <div className="landing-logo-wrapper">
          <img src={logo} className="App-logo landing-logo" alt="logo" />
        </div>
        <div className="landing-form-fields">
          <label>Email</label>
          <input type="email" value={emailL} onChange={emailChangeHandler} />
          <label>Password</label>
          <input type="text" value={password} onChange={passChangeHandler} />
          <div className={btn_class}>
            <button type="button" onClick={SignUpHandler}>
              {btn_msg}
            </button>
          </div>
        </div>
        <div className={acc_created}>Account Created!</div>
        <h2>$0 = infinite words with our AI</h2>
        {show_paypal}
        <div className="landing-phrase">500k words would cost $5750 with the cheapest writers in the world at $11.5 per 1k words</div>
        <div className="landing-phrase">First create the account, then verify you email for the ultimate SEO experience</div>
      </div>
    </div>
  );
}

export default Landing;
