import "./css/EmailVerifier.css";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import Extensions from "../Extensions";
import CheckJWTer from "../hooks/useCheckJWT";
import EmailVerifierProj from "./EmailVerifierProj";
import arrowsrotate from "../media/arrows-rotate-solid.svg";


var mock_projects = {
	projects: [
		{
			_id: "id1",
			user_id: "user_id1",
			good_emails: ["700"],
			finished: true
		}]
};

var export_id = "id1";


function EmailVerifier(props) {
	const [emails, setEmails] = useState("");
	const [error_message, setError_message] = useState("");
	const [projects_email_verify, setprojects_email_verify] = useState("");
	const context = useContext(SailorContent);
	const [data] = CheckJWTer();

	const add_email_verifier_proj = () => {
		setError_message("");
		const regex = /(?:\r\n|\r|\n)/g;
		let cleaned_dts = emails.replace(regex, ",").split(",");
		let payload = { domains: cleaned_dts.slice(0, 50000) };
		fetch(context.api_url + "Emails/verify-emails-bulk", {
			method: "post",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer  ${context.jwt}`,
				Origin: "",
				Host: "localhost",
			},
		})
			.then((r) => {
				return r.json();
			})
			.then((rx) => {
				if (rx.hasOwnProperty("progress") && rx.progress === "starting to clean") {
					refresh_email_verification_projects();
				}
				else if (rx.hasOwnProperty("progress") && rx.progress === "error adding project") {
					setError_message(<div>something went horribly wrong while adding your project</div>);
				}
				else if (rx.hasOwnProperty("error") && rx.error === "not verified") {
					setError_message(<div>Account not verified, please click the link in the email</div>);
				}
			})
			.catch((err) => {
				console.log(err, "fucked up adding email verification project");
				setError_message(<div>something went horribly wrong while adding your project</div>);
			});
	};

	const propErrorMessage = (thing) => {
		setError_message(thing);
	};

	const map_email_verify_projs = () => {
		let z = mock_projects.projects.map((arr) => (
			<EmailVerifierProj
				key={arr._id}
				_id={arr._id}
				good_emails={arr.good_emails}
				finished={arr.finished}
				setErrMsg={propErrorMessage}
			/>
		));
		setprojects_email_verify(<div className="kwc-projects">{z}</div>);
	};

	const refresh_email_verification_projects = () => {
		setError_message("");
		fetch(context.api_url + "Emails/get-all-verified-emails", {
			method: "get",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer  ${context.jwt}`,
				Origin: "",
				Host: "localhost",
			},
			mode: "cors",
		})
			.then((r) => {
				return r.json();
			})
			.then((rx) => {
				if (rx.hasOwnProperty("projects") && rx.projects != null) {
					mock_projects = rx;
					console.log(mock_projects, "added the response to the mock projects");
				}
				map_email_verify_projs();
			})
			.catch((error) => {
				console.log(error);
				map_email_verify_projs();
			});
	};

	useEffect(() => {
		try {
			refresh_email_verification_projects();
		} catch (ex) {
			return;
		}
	}, []);

	const submitHandler = (event) => {
		event.preventDefault();
		try {
			add_email_verifier_proj();
		} catch (ex) {
			return;
		}
	};

	const inputChangeHandler = (event) => {
		let cleaned_kwc = event.target.value.replace("*", "");
		setEmails(cleaned_kwc);
	};

	return (
		<div>
			<MainNavigation current="Bulk Email Verification" />
			<div className="kwc-wrapper">
				<div className="kwclusters">
					<div>
						<div className="compare-credit">
							Add up to 50000 emails at once to verify them and clean your lists, add them in the input box separated by comma or line break.
						</div>
						<div className="kwc-info">Add emails separated by comma or line break, up to 50000 emails</div>
						<div className="ev-error">{error_message}</div>
						<textarea type="text" value={emails} onChange={inputChangeHandler} placeholder="Add up to 50000 emails here..." />
						<div className="kwc-buttons">
							<button onClick={submitHandler}>Add Email Verification Project</button>
							<button onClick={refresh_email_verification_projects} className="refresh-image"><img src={arrowsrotate} alt="arrows rotate icon" />  Check Status of Projects</button>
						</div>
					</div>
					<div className="kwc-bar-label">Projects</div>
					<div className="kwc-bar-separator"></div>
				</div>
				<div>{projects_email_verify}</div>
			</div>
		</div>
	);
}

export default EmailVerifier;
