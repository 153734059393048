import React, { useState, useEffect, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./css/Card.css";
import SailorContent from "../store/SailorContext";
import eventTrack from "./eventTrack";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const CardLandingPaypal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(SailorContent);
  const history = useHistory();

  const confirm_payment = () => {
    let payload = {
      bal: props.amount,
    };
    fetch(context.api_url + "GenerateText/add-balance", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        //redirect back to the generating screen
        eventTrack.bind(this, "payments", "landing-paid", "paid-200", props.amount);
        history.push({
          pathname: "/textron",
        });
      })
      .catch(() => {
        console.log("Adding funds failed for some reason, please contact us at info@kazanseo.com");
      });
  };

  return (
    <div className="payment-form">
      <PayPalScriptProvider options={{ "client-id": "Acq6bIwPdUveiehrhrdD3PIkEh3Z_KOrAxHsjQaOZf8ePWyT2jgCPBLq5X5pP5mthRqBG8CEiVMbb1mw" }}>
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: props.amount,
                    currency_code: "USD",
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              const name = details.payer.name.given_name;
              alert(`Transaction completed by ${name}`);
              confirm_payment();
            });
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default withRouter(CardLandingPaypal);
