import React, { useState, useEffect, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import SailorContent from "../store/SailorContext";
import "./css/Verify.css";
import MainNavigation from "./MainNavigation";

const Verify = (props) => {
  const context = useContext(SailorContent);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("Verifying Account...");

  const currentLocation = window.location.href.split("?verifytoken=");

  const verify_response = () => {
    let token = "";
    if (currentLocation.length > 0) {
      token = currentLocation[1];
    }

    fetch(context.api_url + `SailorMonolithic/VerifyEmail/?verifytoken=${token}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("msj")) {
          if (rx.msj === "error adding balance") {
            setErrorMessage("error adding trial balance");
          } else if (rx.msj === "successful trial account validated") {
            history.push({
              pathname: "/",
            });
          }
        } else {
          setErrorMessage("no message back from the server, you set it on fire, good job!");
        }
      })
      .catch(() => {
        setErrorMessage("server error, you set it on fire, good job!, or your account doesn't even exist");
      });
  };

  useEffect(() => {
    try {
      verify_response();
    } catch (ex) {
      //random comment
      return;
    }
  }, []);

  return (
    <div>
      <MainNavigation current="Verify" />
      <div className="mainVerify">
        <h2>{errorMessage}</h2>
      </div>
    </div>
  );
};

export default Verify;
