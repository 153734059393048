import React, { useState, useEffect, useContext } from "react";
import "./css/TextExtraction.css";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import TextExtractionProjects from "./TextExtractionProjects";
import CheckJWTer from "../hooks/useCheckJWT";

let controller = null;
let signal = null;
let content_div = "";
let results_or_projects = "";

function ExtractTextHttp(url_array, context) {
  controller = new AbortController();
  signal = controller.signal;
  let jsonObject = { urls: url_array };
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + "TextExtractor/Extractor", {
        method: "post",
        signal: signal,
        body: JSON.stringify(jsonObject),
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "kazanseo.com",
          Authorization: `Bearer  ${context.jwt}`,
        },
        mode: "cors",
      });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject("404 error");
      }
    } catch (ex) {
      if (isAbortError(ex)) {
        console.log(ex.message);
        reject("aborted");
      } else {
        console.log("random other error");
        console.log(ex);
        reject("random other error");
      }
    }
  });
  promise.cancel = () => controller.abort();
  return promise;
}

function PullPrevExtractors(context) {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + "TextExtractor/PullExtracts", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "kazanseo.com",
          Authorization: `Bearer  ${context.jwt}`,
        },
        mode: "cors",
      });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject("response pulling projects was not ok");
      }
    } catch (ex) {
      reject("exception thrown when pulling projects");
    }
  });
  promise.cancel = () => controller.abort();
  return promise;
}

function isAbortError(error) {
  if (error && error.name === "AbortError") {
    return true;
  }
  return false;
}

function CountWords(array) {
  return array
    .map((e) => e.text)
    .join(" ")
    .split(" ").length;
}

function JoinText(array) {
  let a = [];
  for (let i = 0; i < array.length; i++) {
    const url = array[i].url;
    const text = array[i].text;
    const joined = url + " \n\n##########\n##########\n\n " + text + " \n\n##########\n##########\n\n ";
    a.push(joined);
  }
  return a.join(" ");
}

//copy the whole contents of the div
function copyDivToClipboard() {
  var range = document.createRange();
  range.selectNode(document.getElementById("a"));
  window.getSelection().removeAllRanges(); // clear current selection
  window.getSelection().addRange(range); // to select text
  document.execCommand("copy");
  window.getSelection().removeAllRanges(); // to deselect
}

function TextExtraction() {
  const [urls, setUrls] = useState("");
  const [query, setQuery] = useState(null);
  const [projButton, setProjButton] = useState(null);
  const [pbutton, setPbutton] = useState("req");
  const [sendingReq, setSendingReq] = useState(false);
  const [projects_or_results, setProjects_or_results] = useState(results_or_projects);
  const [copyButton, setCopyButton] = useState(null);
  const context = useContext(SailorContent);

  const [data] = CheckJWTer();

  const cancelRequest = (event) => {
    try {
      controller.abort();
      query.cancel();
    } catch (error) { }
    setPbutton("req");
  };

  const copyResults = () => {
    try {
      copyDivToClipboard();
    } catch (error) { }
  };

  //when clicking on a project it displays it in the results tab
  const clickOnProject = (thing) => {
    setProjects_or_results(
      <div id="a" className="results-not-editor display-linebreak">
        {thing}
      </div>
    );
    setCopyButton(
      <button className="extract-show-results" onClick={copyResults}>
        Copy Result to Clipboard
      </button>
    );
  };

  //function to pull projects
  const pullExtractionProjects = () => {
    const pull_projs = PullPrevExtractors(context);
    pull_projs
      .then((projs_results) => {
        console.log(projs_results);
        for (let proj of projs_results) {
          //only add projects that don't already exist with the same id
          if (context.url_extractor.filter((e) => e._id === proj._id).length === 0) {
            context.url_extractor.unshift(proj);
          }
        }
        mapExtractionProjects();
      })
      .catch((whatnow) => {
        mapExtractionProjects();
        console.log(whatnow);
      });
  };

  //pulls all the projects you have in your account as soon as the component loads
  useEffect(() => {
    pullExtractionProjects();
    setProjButton(<button type="submit">Extract Text</button>);
  }, []);

  //this just updates the projects
  const mapExtractionProjects = () => {
    let z = context.url_extractor.map((arr) => (
      <TextExtractionProjects
        key={arr._id}
        _id={arr._id}
        user_id={arr.user_id}
        urls={arr.urls}
        urls_count={arr.urls.length}
        words={CountWords(arr.urls)}
        text={JoinText(arr.urls)}
        ShowInResults={clickOnProject}
        date={arr.date}
      />
    ));
    setProjects_or_results(<div className="pick-text-extractor-projs">{z}</div>);
    setCopyButton();
  };

  useEffect(() => {
    mapExtractionProjects();
  }, [context.url_extractor]);

  //content editor of the top div which has the urls
  const contentEditHandler = (event) => {
    setUrls(event.target.outerText);
  };

  const submitHandler = () => {
    //this prevents the page from being reloaded after submitting the form
    console.log("submithandler urls");
    setSendingReq(true);
    console.log(urls);
    console.log("content_div");
    setPbutton("cancel");
    const nameArr = urls.replace(/\n/g, ",").split(",");
    for (let i = 0; i < nameArr.length; i++) {
      nameArr[i] = nameArr[i].trim();
    }
    if (nameArr.length > 20) {
      setProjects_or_results(<div className="more-than-20">we only allow 20 urls at the same time, later we'll make it a premium to add more than 20</div>);
      setPbutton("req");
      console.log("we only allow 20 urls at the same time");
      return;
    }
    if (nameArr.length === 0) {
      console.log("urls is empty fors some reason");
      return;
    }
    console.log(nameArr);
    const q = ExtractTextHttp(nameArr, context);
    setQuery(q);
    q.then((results) => {
      if (results === "We only allow 20 urls at the time") {
        console.log("requested over 20 results");
      }
      console.log("success");
      console.log(results);
      try {
        if (context.url_extractor.filter((e) => e._id === results._id).length === 0) {
          context.url_extractor.unshift(results);
        }
        setProjects_or_results(
          <div id="a" className="results-not-editor display-linebreak">
            {JoinText(results.urls)}
          </div>
        );
        setSendingReq(false);
        setCopyButton(
          <button className="extract-show-results" onClick={copyResults}>
            Copy Result to Clipboard
          </button>
        );
      } catch (error) {
        setSendingReq(false);
      }
    }).catch((whattodo) => {
      console.log("something went wrong");
      setSendingReq(false);
    });
  };

  let extract_button = (<button onClick={submitHandler}>Extract Text</button>);
  let cancel_button = (<button onClick={cancelRequest}>
    Processing... Cancel Request? It takes like 1 second per URL
  </button>);

  return (
    <div>
      <MainNavigation current="Text Extraction" />
      <div className="extraction-wrapper">
        <div className="extract-form">
          <div className="extract-form-top">
            <div className="extract-form-title">
              <label>Type Urls Separated by Comma or Line Break to Extract Text</label>
              <div className="compare-credit compare-instructions"><a href="https://kazanseo.com/blog/kazanseo-text-extractor-how-it-works/" rel="noreferrer" target="_blank">How to Use Guide</a></div>
              <div className="extract-form-editor" contentEditable="true" suppressContentEditableWarning onInput={contentEditHandler}>
                {content_div}
              </div>
            </div>
          </div>
        </div>
        <div className="extract-buttons-flex">
          <div className="extract-form-actions">{sendingReq ? cancel_button : extract_button}</div>
          <button className="extract-show-results" onClick={pullExtractionProjects}>
            Projects
          </button>
        </div>
        <div className="extract-results-projects">
          <div className="projects-copy-button-wrapper">
            <div>
            </div>
          </div>
          <div>{projects_or_results}</div>
          <div>{copyButton}</div>
        </div>
      </div>
    </div>
  );
}

export default TextExtraction;
