import React, { useState, useEffect, useContext } from "react";
import "./css/Refill.css";
import { withRouter, useHistory } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import logo from "../media/logo.svg";

function EmailLanding(props) {
  const [balance, setBalance] = useState("pulling active sub...");
  const [email, setEmail] = useState("");
  const context = useContext(SailorContent);
  const [emailL, setEmailL] = useState("");
  const [password, setPassword] = useState("");
  const [btn_msg, set_btn_msg] = useState("Create Account!");
  const [btn_class, set_btn_class] = useState("");
  const [sliderV, setSliderV] = useState(400);
  const [vaCost, setVaCost] = useState(125);
  const [kaCost, setKaCost] = useState(25);
  const [saveCost, setSaveCost] = useState(375);
  const [ddate, dsetDate] = useState(() => {
    const date = new Date();

    const dateCopy = new Date(date);
    dateCopy.setDate(date.getDate() + 2);

    const year = dateCopy.getFullYear();
    const month = dateCopy.getMonth();
    const day = dateCopy.getDate();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const withHyphens = [day, monthNames[month], year].join("-");
    console.log(withHyphens); // 👉️ "2023-1-4"

    return withHyphens;
  });

  const [show_paypal, set_show_paypal] = useState("");

  const history = useHistory();

  const json_keys = (arr, target) => target.every((v) => arr.includes(v));

  const emailChangeHandler = (event) => {
    setEmailL(event.target.value);
    console.log(emailL);
  };

  const passChangeHandler = (event) => {
    setPassword(event.target.value);
    console.log(password);
  };

  let go_pro = (<button onClick={() => {
    history.push({
      pathname: "/emailupgradeexperience",
    });
  }}>Go Pro And Access Everything Unlimited</button>);

  const SignUp = () => {
    let payload = { email: emailL, pass: password };
    fetch(context.api_url + "SailorMonolithic/SignUp", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        const keys = Object.keys(rx);
        if (rx.email.includes("Already in Use")) {
          setBalance(
            <div className="ext-link-message">Email Already In Use</div>
          );
          set_btn_msg("Try Again!");
        } else if (json_keys(keys, ["oblastjwttoken", "user_ID"])) {
          context.jwt = rx.oblastjwttoken;
          context.user_id = rx.user_ID;
          //store the jwt
          localStorage.setItem("potentially_sailor_JWT", rx.oblastjwttoken);
          localStorage.setItem("potentially_sailor_UID", rx.user_ID);
          console.log("success");
          context.isLoggedIn = true;
          set_btn_class("landing-button-hidden");
          history.push({
            pathname: "/emailupgradeexperience",
          })
          // setBalance(
          //   <div className="sign-up-form-title">
          //     Thank you! Subscribe below and access the full email finder capabilities right away!
          //   </div>
          // );
          set_show_paypal(go_pro);
        } else {
          setBalance(
            <div className="ext-link-message">Weird Error, Try Again</div>
          );
        }
      })
      .catch(() => {
        console.log("Signing up failed");
      });
  };

  const SignUpHandler = () => {
    if (
      /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
        emailL
      ) === false
    ) {
      console.log("invalid email");
      console.log(emailL);
      setBalance(
        <div className="ext-link-message">Invalid Email, Try again</div>
      );
      return;
    }
    if (emailL.length < 5 || password.length < 5) {
      setBalance(
        <div className="ext-link-message">
          Email and pass has to be longer than 5 characters
        </div>
      );
    } else {
      try {
        set_btn_msg("Creating...");
        setBalance("");
        SignUp();
      } catch (ex) {
        set_btn_msg("Try Again!");
      }
    }

    // window.ttq.track('SubmitForm');
    // console.log(window.ttq);
  };

  const bal_response = () => {
    let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
    if (tokener.token !== null) {
      context.jwt = tokener.token;
    }
    fetch(context.api_url + "Emails/pull-sub", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${tokener.token}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("error") && rx.error === "not verified") {
          setBalance(
            <div className="bad-result">
              Please verify your account to access the free tier, all you have
              to do is click on the link we sent to your email.
            </div>
          );
          set_show_paypal(go_pro);
          return;
        }
        if (!rx.hasOwnProperty("subq")) {
          console.log(rx);
          let error_s = "";
          for (const key of Object.keys(rx)) {
            error_s = error_s + key + ' = ' + rx[key] + '\n';
          }
          setBalance(<div className="ws">{error_s}</div>);
          set_show_paypal(go_pro);
          // set_show_paypal("thank you for being awesome, get the extension above");
          return;
        }
        if (rx.subq === "true") {
          setBalance(
            `Last Subbed ${rx.last_payment} You're good to go! thank you!`
          );
          set_show_paypal(
            "thank you for being awesome, start hunting for emails"
          );
        } else if (rx.subq === "never susbcribed") {
          setBalance(
            <div>
              <div>You're not a member yet it's a 1 time payment for life</div>
              <div className="sign-up-form-title">
                Become a LifeTimePro Below For Insane Email Sniping and Maximum Outreaching
              </div>
            </div>
          );
          set_show_paypal(go_pro);
          // set_show_paypal("thank you for being awesome, get the extension above");
        } else if (rx.last_payment === "expired subscription") {
          setBalance(
            "Expired Subscription, please renew to access the unlimited email finder"
          );
          set_show_paypal(go_pro);
          // set_show_paypal("thank you for being awesome, get the extension above");
        } else {
          setBalance("error pulling the sub, sorry :( try again later");
          set_show_paypal(go_pro);
        }
      })
      .catch(() => {
        setBalance("Sign Up Now for the full experience");
        // set_show_paypal(<CardFormPaypalSub />);
      });
  };

  const get_email_user = () => {
    let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
    fetch(context.api_url + "SailorMonolithic/GetEmail", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${tokener.token}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("em")) {
          setEmail(rx.em);
        } else {
          setEmail("");
        }
      })
      .catch(() => {
        setEmail("");
      });
  };

  useEffect(() => {
    try {
      bal_response();
      get_email_user();
    } catch (ex) {
      setBalance("pulling sub failed");
      return;
    }
  }, []);

  // let new_date = ddate;
  // new_date.setDate(new_date + 1);
  // new_date.set

  const sliderVHandler = (event) => {
    let amount_slider = event.target.valueAsNumber;
    let vaCostX = Math.round(amount_slider * 0.3125);
    let kaCostX = Math.round(amount_slider * 0.0312);
    let savings = vaCostX - kaCostX;
    setSliderV(amount_slider);
    setVaCost(vaCostX);
    setKaCost(kaCostX);
    setSaveCost(savings);
  };

  return (
    <div>
      <MainNavigation current="Email Sniper Subscribe" />
      <div className="refill">
        <div className="slidecontainer">
          <div className="refill-square">
            <h1>Create a <span className="nst">free</span> account.</h1>
            <h3 className="h3-sub">KazanSEO can be used for free, forever. 60 searches per month with 1k results each for free!</h3>
            <div className="center-kazano">
              <img src={logo} alt="kazano" className="login-kazano-r" />
            </div>
          </div>
        </div>
        <div className="signupper">
          <h3>Do you have the email finder for limitless outreach already?</h3>
          <div className="subtitle-exp normal-st sub-margin">
            After signing up and verifying your account by clicking on the link on your email you can use the email sniper immediately.
          </div>
          <h4 id="scrolltome">{balance}</h4>
          {show_paypal === "" ? (
            <div className="landing-form-fields">
              <div className="sign-up-form-title">
                Create an account to access the email finder:
              </div>
              <label>Email</label>
              <input
                type="email"
                value={emailL}
                onChange={emailChangeHandler}
              />
              <label>Password</label>
              <input
                type="text"
                value={password}
                onChange={passChangeHandler}
              />
              <div className={btn_class}>
                <button type="button" onClick={SignUpHandler}>
                  {btn_msg}
                </button>
              </div>
            </div>
          ) : (
            show_paypal
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailLanding;
