import { useState, useContext, useEffect } from "react";
import SailorContent from "../store/SailorContext";

const CheckJWTer = () => {
  const context = useContext(SailorContent);
  const [data, setData] = useState(null);
  useEffect(() => {
    let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
    let user_ider = { token: localStorage.getItem("potentially_sailor_UID") };
    if (tokener.token !== null && user_ider.token !== null) {
      context.jwt = tokener.token;
      context.user_id = user_ider.token;
      return;
    }
    fetch(context.api_url + "SailorMonolithic/CheckJWTToken", {
      method: "post",
      body: JSON.stringify(tokener),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${tokener.token}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        //stay in the page
        context.jwt = rx.oblastjwttoken;
        context.user_id = rx.user_ID;
        context.isLoggedIn = true;
        localStorage.setItem("potentially_sailor_JWT", rx.oblastjwttoken);
        localStorage.setItem("potentially_sailor_UID", rx.user_ID);
        console.log("checkjwt global:");
        console.log(rx);
        setData(rx);
      })
      .catch(() => {
        console.log("not logged in, go back to the front page");
        window.location.replace("/");
      });
  }, []);
  return [data];
};

export default CheckJWTer;
