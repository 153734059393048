import React, { useState, useEffect, useContext } from "react";
import "./css/ThankYou.css";
import { withRouter, useHistory, Link } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";

function ThankYouEmails(props) {
  const [balance, setBalance] = useState("pulling active sub...");

  const history = useHistory();

  return (
    <div>
      <MainNavigation current="Thank You" />
      <div className="refill">
        <div className="slidecontainer">
          <div className="refill-square">
            <h1>THANK YOU for joining to our Email Finder</h1>
            <div>Please head over to our Email Finder page and enjoy unlimited domain searches</div>
            <div>
              Go to the{" "}
              <Link className="link" to="/emailsearch">
                Email Sniper
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYouEmails;
