import "./css/BulkDetector.css";
import "./css/KeywordSharing.css";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import KeywordSharingProject from "./KeywordSharingProject";
import CheckJWTer from "../hooks/useCheckJWT";

let project = "";
let ce = null;

function KeywordSharing(props) {
  const [groupings, setgroupings] = useState("");
  const [urls_typed, seturls_typed] = useState(" ");
  const [projects_grouper, set_projects_grouper] = useState("");
  const [projectS, setProjectS] = useState("");
  const [errorMesage, setErrorMessage] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [groupButton, setgroupButton] = useState("Add Keywords to Group Them");
  const context = useContext(SailorContent);
  const [data] = CheckJWTer();

  const checker_keys = (arr, target) => target.every((v) => arr.includes(v));

  //this captures the paste event and only pastes the raw plain text
  useEffect(() => {
    ce = document.querySelector(".bulk-keyworder");
    ce.addEventListener("paste", function (e) {
      e.preventDefault();
      var text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    });
  }, []);

  const add_grouper = () => {
    if (groupings.length > 20000) {
      //   alert("Woa, too many urls! We process 200 per batch, chill out");
      setErrorMessage("Woa, too many keywords! We process 20k per batch, chill out, try again");
      return;
    }
    seturls_typed("");
    setErrorMessage("");
    let payload = { list_keyword: groupings };
    fetch(context.api_url + "Keywords/AddParenting", {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        if (rx.hasOwnProperty("_id")) {
          map_keyword_parenting();
          refresh_groupings();
        } else {
        }
      })
      .catch(() => { });
  };

  const pull_1_dect = (id) => {
    let foundIndex = context.keyword_parenting.findIndex((x) => x._id === id);
    if (foundIndex >= 0) {
      project = context.keyword_parenting[foundIndex];
      map_groupings(project);
      console.log("found kw parenting");
    }
  };

  const map_groupings = (rx) => {
    // setProjectS(rx);
    const keys = Object.keys(rx);
    if (checker_keys(keys, ["_id", "user_id", "kws", "parenting"])) {
      let overlaps = rx.parenting.map((arr) => (
        <div className="grouping-overlaps">
          <div className="grouping-seed">{arr[0]}</div>
          {arr.map((clu) => (
            <div>
              <div>{clu}</div>
            </div>
          ))}
        </div>
      ));

      set_projects_grouper(
        <div className="grouped-clicked-project">
          <div className="kws-clicked-seed">Seed: {rx.kws[0]}</div>
          <div className="grouping-wrapper">{overlaps}</div>
        </div>
      );
    } else {
      set_projects_grouper(<div>Something messed up, please pull/refresh projects again</div>);
    }
  };

  const map_keyword_parenting = () => {
    let z = context.keyword_parenting.map((arr) => (
      <KeywordSharingProject key={arr._id} id={arr._id} user_id={arr.user_id} seed={arr.kws[0]} selection={click_on_dect_proj} total={arr.kws.length} />
    ));
    set_projects_grouper(<div className="kwc-projects">{z}</div>);
  };

  const refresh_groupings = () => {
    fetch(context.api_url + "Keywords/PullAllParenting", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
        Origin: "",
        Host: "localhost",
      },
      mode: "cors",
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        console.log(rx);
        if (rx.hasOwnProperty("projs") && rx.projs != null) {
          for (let proj of rx.projs) {
            //only add projects that don't already exist with the same id
            if (context.keyword_parenting.filter((e) => e._id === proj._id).length === 0) {
              context.keyword_parenting.unshift(proj);
            }
            let foundIndex = context.keyword_parenting.findIndex((x) => x._id === proj._id);
            context.keyword_parenting[foundIndex] = proj;
            context.keyword_parenting[foundIndex].kws = proj.kws;
            context.keyword_parenting[foundIndex].parenting = proj.parenting;
          }
        }
        map_keyword_parenting();
      })
      .catch((error) => {
        console.log(error);
        map_keyword_parenting();
      });
  };

  const click_on_dect_proj = (thing) => {
    //pull project here with http
    pull_1_dect(thing);
  };

  useEffect(() => {
    try {
      refresh_groupings();
    } catch (ex) {
      return;
    }
  }, []);

  useEffect(() => {
    map_keyword_parenting();
  }, [context.keyword_parenting]);

  const submitHandler = (event) => {
    event.preventDefault();
    if (groupButton === "Processing...") {
      return;
    }
    setgroupButton("Processing...");
    setTimeout(() => {
      setgroupButton("Add Keywords to Group Them");
      setErrorMessage("");
      console.log("Grouping Button Activated Again");
    }, 3000);
    try {
      add_grouper();
      refresh_groupings();
      let clear = "";
      seturls_typed(clear);
    } catch (ex) {
      return;
    }
  };

  const inputChangeHandler = (event) => {
    const regex = /(?:\r\n|\r|\n)/g;
    let cleaned_dts = event.target.innerText.replace(regex, ",").split(",");
    setgroupings(cleaned_dts);
  };

  const refreshProjectsHandler = (event) => {
    refresh_groupings();
  };

  return (
    <div>
      <MainNavigation current="Keyword Sharing" />
      <div className="kwc-wrapper">
        <div className="kwclusters-bulk">
          <div>
            <div className="kwc-info">Input Keywords to GROUP Separated by Comma or New Line</div>
            <div className="compare-credit compare-instructions"><a href="https://kazanseo.com/blog/kazanseo-keyword-sharing-how-it-works/" target="_blank" rel="noreferrer">How it works</a></div>
            <div className="bulk-keyworder" contentEditable="true" suppressContentEditableWarning onInput={inputChangeHandler}>
              {urls_typed}
            </div>
            {/* <input type="text" value={urls_typed} onChange={inputChangeHandler} /> */}
            <div className="kwc-buttons">
              <button onClick={submitHandler}>
                {groupButton}
              </button>
              <button onClick={refreshProjectsHandler}>
                View Projects
              </button>
            </div>
            <div className={errorMesage === "" ? "compare-credit detector-alerts-blank" : "compare-credit detector-alerts"}>{errorMesage}</div>
            <div className="compare-credit">Click on the dummy project to understand.</div>
            <div className="compare-credit">
              Each item in the groupings represents a child page for the highlighted keyword, which would be the parent page. Ignore the groupings that don't
              make sense for your website's use case.
            </div>
            <div className="compare-credit">Click "View projects" to pull previous projects</div>
          </div>
          <div className="kwc-bar-separator"></div>
        </div>
        <div>{projects_grouper}</div>
      </div>
    </div>
  );
}

export default KeywordSharing;
