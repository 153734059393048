import "./App.css";
import Editor from "./components/Editor";
import React, { useContext, useState, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import PickProject from "./components/PickProject";
import SailorContent from "./store/SailorContext";
import Login from "./components/Login";
import usePageTracking from "./components/usePageTracking";
import CompareKeywords from "./components/CompareKeywords";
import TextExtraction from "./components/TextExtraction";
import Generator from "./components/Generator";
import Refill from "./components/Refill";
import Verify from "./components/Verify";
import ForgotPass from "./components/ForgotPass";
import KeywordCluster from "./components/KeywordCluster";
import GeneratorFree from "./components/GeneratorFree";
import BulkKeywordCluster from "./components/BulkKeywordCluster";
import Detector from "./components/Detector";
import BulkDetector from "./components/BulkDetector";
import KeywordSharing from "./components/KeywordSharing";
import DetectionLog from "./components/DetectionLog";
import ThankYou from "./components/ThankYou";
import EmailVerifier from "./components/EmailVerifier";


import anime from "./anime";
import Landing from "./components/Landing";
import RefillSub from "./components/RefillSub";
import CheckJWTer from "./hooks/useCheckJWT";
import ThankYouEmails from "./components/ThankYouEmails";
import ThankYouEmailsB from "./components/ThankYouEmailsB";
import EmailLanding from "./components/EmailLanding";
import EmailSearcher from "./components/EmailSearcher";
import EmailSearcherBulk from "./components/EmailSearcherBulk";
import EmailSearcherSearchEngine from "./components/EmailSearcherSearchEngine";
import EmailCreatedAccount from "./components/EmailCreatedAccount";
import EmailSearcherSearchEngineFree from "./components/EmailSearcherSearchEngineFree";
// const c = document.createElement("canvas");
// document.body.append(c);
// c.style.display = "block";
// c.style.width = "100vw";
// c.style.height = "100vh";
// var ctx = c.getContext("2d");
// var cH;
// var cW;
// var bgColor = "#282c34";
// var animations = [];
// var circles = [];

// var colorPicker = (function () {
//   // var colors = ["#FF6138", "#FFBE53", "#2980B9", "#282741"];
//   var colors = ["#040114", "#001933", "#282c34", "#001638"];
//   var index = 0;
//   function next() {
//     index = index++ < colors.length - 1 ? index : 0;
//     return colors[index];
//   }
//   function current() {
//     return colors[index];
//   }
//   return {
//     next: next,
//     current: current,
//   };
// })();

// function removeAnimation(animation) {
//   var index = animations.indexOf(animation);
//   if (index > -1) animations.splice(index, 1);
// }

// function calcPageFillRadius(x, y) {
//   var l = Math.max(x - 0, cW - x);
//   var h = Math.max(y - 0, cH - y);
//   return Math.sqrt(Math.pow(l, 2) + Math.pow(h, 2));
// }

// function addClickListeners() {
//   document.addEventListener("touchstart", handleEvent);
//   document.addEventListener("mousedown", handleEvent);
// }

// function handleEvent(e) {
//   if (e.touches) {
//     e.preventDefault();
//     e = e.touches[0];
//   }
//   var currentColor = colorPicker.current();
//   var nextColor = colorPicker.next();
//   var targetR = calcPageFillRadius(e.pageX, e.pageY);
//   var rippleSize = Math.min(200, cW * 0.4);
//   var minCoverDuration = 750;

//   var pageFill = new Circle({
//     x: e.pageX,
//     y: e.pageY,
//     r: 0,
//     fill: nextColor,
//   });
//   var fillAnimation = anime({
//     targets: pageFill,
//     r: targetR,
//     duration: Math.max(targetR / 2, minCoverDuration),
//     easing: "easeOutQuart",
//     complete: function () {
//       bgColor = pageFill.fill;
//       removeAnimation(fillAnimation);
//     },
//   });

//   var ripple = new Circle({
//     x: e.pageX,
//     y: e.pageY,
//     r: 0,
//     fill: currentColor,
//     stroke: {
//       width: 3,
//       color: currentColor,
//     },
//     opacity: 1,
//   });
//   var rippleAnimation = anime({
//     targets: ripple,
//     r: rippleSize,
//     opacity: 0,
//     easing: "easeOutExpo",
//     duration: 900,
//     complete: removeAnimation,
//   });

//   var particles = [];
//   for (var i = 0; i < 32; i++) {
//     var particle = new Circle({
//       x: e.pageX,
//       y: e.pageY,
//       fill: currentColor,
//       r: anime.random(24, 48),
//     });
//     particles.push(particle);
//   }
//   var particlesAnimation = anime({
//     targets: particles,
//     x: function (particle) {
//       return particle.x + anime.random(rippleSize, -rippleSize);
//     },
//     y: function (particle) {
//       return particle.y + anime.random(rippleSize * 1.15, -rippleSize * 1.15);
//     },
//     r: 0,
//     easing: "easeOutExpo",
//     duration: anime.random(1000, 1300),
//     complete: removeAnimation,
//   });
//   animations.push(fillAnimation, rippleAnimation, particlesAnimation);
// }

// function extend(a, b) {
//   for (var key in b) {
//     if (b.hasOwnProperty(key)) {
//       a[key] = b[key];
//     }
//   }
//   return a;
// }

// var Circle = function (opts) {
//   extend(this, opts);
// };

// Circle.prototype.draw = function () {
//   ctx.globalAlpha = this.opacity || 1;
//   ctx.beginPath();
//   ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI, false);
//   if (this.stroke) {
//     ctx.strokeStyle = this.stroke.color;
//     ctx.lineWidth = this.stroke.width;
//     ctx.stroke();
//   }
//   if (this.fill) {
//     ctx.fillStyle = this.fill;
//     ctx.fill();
//   }
//   ctx.closePath();
//   ctx.globalAlpha = 1;
// };

// var animate = anime({
//   duration: Infinity,
//   update: function () {
//     ctx.fillStyle = bgColor;
//     ctx.fillRect(0, 0, cW, cH);
//     animations.forEach(function (anim) {
//       anim.animatables.forEach(function (animatable) {
//         animatable.target.draw();
//       });
//     });
//   },
// });

// var resizeCanvas = function () {
//   cW = window.innerWidth;
//   cH = window.innerHeight;
//   c.width = cW * devicePixelRatio;
//   c.height = cH * devicePixelRatio;
//   ctx.scale(devicePixelRatio, devicePixelRatio);
// };

// (function init() {
//   resizeCanvas();
//   if (window.CP) {
//     // CodePen's loop detection was causin' problems
//     // and I have no idea why, so...
//     window.CP.PenTimer.MAX_TIME_IN_LOOP_WO_EXIT = 6000;
//   }
//   window.addEventListener("resize", resizeCanvas);
//   addClickListeners();
//   if (!!window.location.pathname.match(/fullcpgrid/)) {
//     startFauxClicking();
//   }
//   handleInactiveUser();
// })();

// function handleInactiveUser() {
//   var inactive = setTimeout(function () {
//     fauxClick(cW / 2, cH / 2);
//   }, 2000);

//   function clearInactiveTimeout() {
//     clearTimeout(inactive);
//     document.removeEventListener("mousedown", clearInactiveTimeout);
//     document.removeEventListener("touchstart", clearInactiveTimeout);
//   }

//   document.addEventListener("mousedown", clearInactiveTimeout);
//   document.addEventListener("touchstart", clearInactiveTimeout);
// }

// function startFauxClicking() {
//   setTimeout(function () {
//     fauxClick(anime.random(cW * 0.2, cW * 0.8), anime.random(cH * 0.2, cH * 0.8));
//     startFauxClicking();
//   }, anime.random(200, 900));
// }

// function fauxClick(x, y) {
//   var fauxClick = new Event("mousedown");
//   fauxClick.pageX = x;
//   fauxClick.pageY = y;
//   document.dispatchEvent(fauxClick);
// }

function App() {
  const context = useContext(SailorContent);
  const [logged, setLogged] = useState(context.isLoggedIn);

  usePageTracking();

  const onRefreshHandler = (event) => {
    context.isLoggedIn = true;
    setLogged(true);
  };
  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <Login onRefresh={onRefreshHandler} />
        </Route>
        {/* {logged ? (
          <Route path="/projects" exact>
            <PickProject />
          </Route>
        ) : null} */}
        <Route path="/projects" exact>
          <PickProject />
        </Route>

        {/* {logged ? (
          <Route path="/editor" exact>
            <Editor />
          </Route>
        ) : null} */}
        <Route path="/editor" exact>
          <Editor />
        </Route>
        <Route path="/repeated-urls" exact>
          <CompareKeywords />
        </Route>
        <Route path="/text-extractor" exact>
          <TextExtraction />
        </Route>
        <Route path="/textron" exact>
          <Generator />
        </Route>
        <Route path="/refill" exact>
          <Refill />
        </Route>
        <Route path="/keyword-clusters" exact>
          <KeywordCluster />
        </Route>
        <Route path="/bulk-keyword-clusters" exact>
          <BulkKeywordCluster />
        </Route>
        <Route path="/detector" exact>
          <Detector />
        </Route>
        <Route path="/bulk-detector" exact>
          <BulkDetector />
        </Route>
        <Route path="/keyword-sharing" exact>
          <KeywordSharing />
        </Route>
        <Route path="/detection-log" exact>
          <DetectionLog />
        </Route>
        <Route path="/verify" exact>
          <Verify />
        </Route>
        <Route path="/thankyou" exact>
          <ThankYou />
        </Route>
        <Route path="/thankyoumemails" exact>
          <ThankYouEmails />
        </Route>
        <Route path="/thankyouaemails" exact>
          <ThankYouEmailsB />
        </Route>
        <Route path="/forgot-pass" exact>
          <ForgotPass />
        </Route>
        <Route path="/klanding" exact>
          <Landing />
        </Route>
        <Route path="/textronfree" exact>
          <GeneratorFree />
        </Route>
        <Route path="/refillsub" exact>
          <RefillSub />
        </Route>
        <Route path="/emailsearch" exact>
          <EmailSearcher />
        </Route>
        <Route path="/emailsearchbulk" exact>
          <EmailSearcherBulk />
        </Route>
        <Route path="/emailsearchengine" exact>
          <EmailSearcherSearchEngine />
        </Route>
        {/* <Route path="/emailsearchenginefree" exact>
          <EmailSearcherSearchEngineFree />
        </Route> */}
        <Route path="/emailsniper" exact>
          <EmailLanding />
        </Route>
        <Route path="/emailupgradeexperience" exact>
          <EmailCreatedAccount />
        </Route>
        <Route path="/email-verifier" exact>
          <EmailVerifier />
        </Route>
        {/* {logged ? (
          <Route path="/detection-log" exact>
            <DetectionLog />
          </Route>
        ) : null} */}
        {/* <Route path="/*">
          <Redirect to="/" />
        </Route> */}
      </Switch>
    </div>
  );
}

export default App;
