import "./css/Editor.css";
import Extensions from "../Extensions";
import Keywords from "./Keywords";
import React, { useState, useEffect, useContext } from "react";
import MainNavigation from "./MainNavigation";
import { useLocation, useHistory } from "react-router-dom";
import SailorContent from "../store/SailorContext";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


let contenter = null;
let sailorContent = null;
let ce = null;

function SaveProd(project, context) {
  let tokener = { token: localStorage.getItem("potentially_sailor_JWT") };
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(context.api_url + "SailorMonolithic/SaveProject", {
        method: "post",
        body: JSON.stringify(project),
        headers: {
          "Content-Type": "application/json",
          Origin: "",
          Host: "localhost",
          Authorization: `Bearer  ${tokener.token}`,
        },
        mode: "cors",
      });
      if (response.ok) {
        resolve("Proj Saved");
      } else {
        reject("coulnd't save project");
      }
    } catch (ex) {
      console.log("coulnd't save project");
      console.log(ex);
      reject("coulnd't save project");
    }
  });
  return promise;
}

function Editor(props) {
  const [editableContent, setEditableContent] = useState(contenter);
  const [originalContent, setOriginalContent] = useState(contenter);
  const [arrayer, setArrayer] = useState(null);
  const [wordCounter, setWordCounter] = useState(0);
  const [currentWords, setCurrentWords] = useState(0);
  const [projName, setProjName] = useState("");
  const [score, setScore] = useState(0);
  const [saveProj, setSaveProj] = useState("Save Project");
  const location = useLocation();
  const { ider } = location.state;

  const context = useContext(SailorContent);

  const history = useHistory();
  if (ider === null) {
    history.push({
      pathname: "/",
    });
  }

  const redoScore = () => {
    if (Extensions.idExists(ider, context.contents)) {
      let result = Extensions.counterKeywords(contenter, ider, context.contents);
      setArrayer(result);

      let difference = 0;
      let total_words = 0;
      for (let i = 0; i < result.length; i++) {
        difference += result[i].count;
        total_words += result[i].ideal;
      }
      let keywords_optimization = (difference * 75) / total_words;
      let words_optimization = (Extensions.wordCounter(contenter) * 25) / context.avg_total_words;

      if (isNaN(words_optimization)) {
        words_optimization = 0;
      }

      console.log("useeffect - kws optimization", keywords_optimization);
      console.log("useeffect - words optimization", words_optimization);

      let scoring = Math.round((keywords_optimization + words_optimization) * 100) / 100;
      setScore(parseFloat(scoring.toFixed(2)));
      console.log("set score inside useeffect: ", parseFloat(scoring.toFixed(2)));
    }
  };

  //pull the content from the requested id
  //pull the content to edit (if there is any)
  useEffect(() => {
    const result = context.contents.filter((obj) => {
      return obj.id === ider;
    });
    if (result[0] != null) {
      sailorContent = result[0];
      contenter = result[0].content;
      setWordCounter(sailorContent.avg_total_words);
      setEditableContent(contenter);
      setCurrentWords(Extensions.wordCounter(contenter));
      setProjName(sailorContent.title);
    } else {
      setProjName("ERROR PROJECT NOT FOUND, PLEASE GO BACK AND START AGAIN");
    }
    redoScore();
  }, []);

  //this captures the paste event and only pastes the raw plain text
  useEffect(() => {
    ce = document.querySelector(".editor-content");
    ce.addEventListener("paste", function (e) {
      e.preventDefault();
      var text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    });
  }, []);

  const contentEditHandler = (event) => {
    let thing = Extensions.strip(event);
    let result = Extensions.counterKeywords(thing.toLowerCase(), ider, context.contents);
    let difference = 0;
    let total_words = 0;
    for (let i = 0; i < result.length; i++) {
      difference += result[i].count;
      total_words += result[i].ideal;
    }
    let keywords_optimization = (difference * 75) / total_words;
    let words_optimization = (currentWords * 25) / wordCounter;

    if (isNaN(words_optimization)) {
      words_optimization = 0;
    }

    console.log("edit handler - kws optimization", keywords_optimization);
    console.log("edit handler - words optimization", words_optimization);

    setScore(Math.round((keywords_optimization + words_optimization) * 100) / 100);
    setArrayer(result);
    setCurrentWords(Extensions.wordCounter(thing));
    setEditableContent(event);
  };

  //highlight words
  const changeWords = (word) => {
    let replaceEditableContent = editableContent;
    let originalC = editableContent;
    setOriginalContent(originalC);


    let bigString = replaceEditableContent;
    let substring = word;

    let lowerBigString = bigString.toLowerCase();
    let lowerSubstring = substring.toLowerCase();

    let positions = [];
    let position = lowerBigString.indexOf(lowerSubstring);

    while (position !== -1) {
      positions.push(position);
      position = lowerBigString.indexOf(lowerSubstring, position + 1);
    }

    // console.log("positions: ", positions);

    bigString = bigString.slice(0, positions[0]) + "<strong>[[" + bigString.slice(positions[0], positions[0] + word.length) +
      "]]</strong>" + bigString.slice(positions[0] + word.length);

    let last_inc = 21;
    for (let i = 1; i < positions.length; i++) {
      bigString = bigString.slice(0, positions[i] + last_inc) + "<strong>[[" + bigString.slice(positions[i] + last_inc, positions[i] + last_inc + word.length) +
        "]]</strong>" + bigString.slice(positions[i] + last_inc + word.length);
      last_inc += 21;
    }

    if (positions.length > 0) {
      setEditableContent(bigString);
    }
  };

  const unChangeWords = () => {
    setEditableContent(originalContent);
  };

  const saveProjHandler = () => {
    const result = context.contents.filter((obj) => {
      return obj.id === ider;
    });
    let resulter = result[0];
    if (resulter != null) {
      resulter.content = editableContent;
      resulter.user_id = context.user_id;
      const q = SaveProd(resulter, context);
      q.then((resultx) => {
        console.log("saved successfully");
        setSaveProj("Saved!");
      }).catch((whattodo) => {
        setSaveProj("Error!");
        console.log(whattodo);
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSaveProj("Save Project");
    }, 5000);
  }, [saveProj]);

  console.log("current score outside: ", score);

  return (
    <div className="mainBox">
      <MainNavigation current="Content Optimizer Editor" />
      <div className="editor">
        <div className="editor-content" onInput={contentEditHandler}>
          <CKEditor
            editor={ClassicEditor}
            data={editableContent}
            onReady={editor => {
              // You can store the "editor" and use when it is needed.
              // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              // console.log(event);
              // console.log(editor);
              // let content = editor.data.get();
              // console.log("content", content);
              // console.log("event", event);
              contentEditHandler(editor.data.get());
            }}
            onBlur={(event, editor) => {
              // console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              // console.log('Focus.', editor);
            }}
          />
        </div>
        <div className="content-suggestions">
          <div className="top-square">
            <div className="proj-namer">
              <div className="words-proj">Project Name:</div>
              <div className="project-name">{projName}</div>
            </div>
            <button type="button" onClick={saveProjHandler}>
              {saveProj}
            </button>
            <div className="word-counter">
              <div className="words">SCORE:</div>
              <div className="current-score">[{score}/100]</div>
              <div className="words">WORDS:</div>
              <div className="current-words">{currentWords}</div>
              <div className="total-words">{wordCounter}</div>
            </div>
          </div>
          <div className="content-improver">
            <div className="content-title-results">Results</div>
            <div className="content-improver-separator"></div>
            <div>{arrayer != null ? arrayer.map((arr) => <Keywords key={arr.keyword} keyword={arr.keyword} current={arr.count} ideal={arr.ideal} change={changeWords} unchange={unChangeWords} />) : ""}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Editor;
