import React, { useContext } from "react";
import './css/Project.css';
import { useHistory } from 'react-router-dom';
import SailorContent from "../store/SailorContext";

function Project(props) {

  const history = useHistory();
  const context = useContext(SailorContent);

  const projClickHandler = () => {
    // Global.activeId = props.id;
    // history.push("/editor", { id: props.id });
    console.log(props, "props");
    console.log(context, "context");
    history.push({
      pathname: '/editor',
      state: { ider: props.id }
    });

  };

  return (
    <div className="project" onClick={projClickHandler}>
      <div className="project-namer">Project Name:</div>
      <div className="project-title">{props.title.substr(0, 55)}</div>
      <div className="project-keywords">Keywords to Optimize:</div>
      <div className="project-keywords-number">{props.keywords}</div>
    </div>
  );
}

export default Project;