import React, { useState, useEffect } from "react";
import "./css/DetectionLogs.css";
import envelope from "../media/envelope.svg";
function copyDivToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

function ResultsEmailsEmails(props) {
  const [openMenu, setOpenMenu] = useState(false);
  const [bodier, setbodier] = useState(props.item);
  const [mailtourl, setMailtourl] = useState(props.item);
  const [trimmedIcon, setTrimmedIcon] = useState(props.item);
  const [email, setEmail] = useState(props.item);

  const copyContent = () => {
    try {
      copyDivToClipboard(email);
      setbodier("copied...");
      setTimeout(() => {
        setbodier(email);
      }, 1000);
    } catch (error) { }
  };

  // console.log(props);

  const mail_select = (email, subject, body) => {
    let body_promo = body + "\n\n\n\nSent with KazanSEO";
    let z = "mailto:" + email + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body_promo);
    // console.log(z);

    return z;
  };

  useEffect(() => {
    let y = mail_select(props.item, props.subject, props.body);
    let m = props.item.substring(0, 200);
    let name_email = m.split("l90l");

    if (name_email.length > 2) {
      // console.log(name_email, "name_email > 2");
      setbodier(name_email[0] + " " + name_email[1] + " | " + name_email[2]);
      setEmail(name_email[2]);
    }
    else if (name_email.length > 1) {
      // console.log(name_email, "name_email > 1");
      setbodier(name_email[0] + " | " + name_email[1]);
      setEmail(name_email[1]);
    }
    else {
      setbodier(m);
      setEmail(m);
    }
    setTrimmedIcon(m);
    setMailtourl(y);
  }, []);



  return (
    <div className="Results-main-emails">
      <div className={`${props.type}`} onClick={copyContent}>
        <div className="social-result">{bodier}</div>
      </div>
      <div className="mailtoicon">
        <a href={mailtourl}>
          <img src={envelope} className="social-icon" />
        </a>
      </div>
    </div>
  );
}

export default ResultsEmailsEmails;
